.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  height: 700px;
  padding: 0 80px;
}

.ibmBtn {
  cursor: pointer;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Btn1 {
  margin-right: 46px;
  transition: filter 0.3s ease-in-out;
}

.Btn2 {
  margin-right: 46px;
  transition: filter 0.3s ease-in-out;
}

.Btn3 {
  transition: filter 0.3s ease-in-out;
}
.ibmBlockWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .wrapper {
    padding: 0px 40px;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    height: 1180px;
    height: auto;
    padding: 0px;
  }
} ;

@media screen and (min-width: 769px) {
  .Btn1 {
    &:hover {
      filter: brightness(2);
    }
  }

  .Btn2 {
    &:hover {
      filter: brightness(2);
    }
  }

  .Btn3 {
    &:hover {
      filter: brightness(2);
    }
  }
}
