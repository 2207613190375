.textInputWrapper {
    box-sizing: border-box;
    clear: both;
    position: relative;
    text-align: inherit;
    display: flex;
    flex-direction: column;
}


.textInput {
    position: relative;
    width: 100%;
    height: 40px;

    padding: 11.5px 12.05px;
    box-sizing: border-box;
    border: none;
    color: #230c39;
    background-color: #ffffff;
    position: relative;
    border-radius: 12px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    border: 1px solid #DFE3EC;

    // &.is-not-valid {
    //     box-shadow: 0px 1px 5px rgba(241, 20, 20, 0.5);
    // }
}

.textInputHasIcon {
    padding-left: 54px;
    padding-right: 0px;
}
.textInputIcon {
    position: absolute;
    z-index: 10;
    width: 20px;
    height: 20px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: 17px;
}

.textInputIconPositionRight {
    position: absolute;
    z-index: 10;
    width: 20px;
    height: 20px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    right: 17px;
}
.textInput:focus {
    // outline: 1px solid #a58ec2;
    outline: none;
    transition: 0.4s;
}
.textInput-error-text {
    margin-top: 0px;
    min-height: 12px;
    margin-bottom: 4px;
    color: red;
    font-size: 10px;
    text-align: left;
}

.textInputButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 12px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 42px;
}

.textError {
    position: absolute;
    z-index: 15;
    
    bottom: -1.3rem;
    left: 0.1rem;
    transform-origin: left;
    width: 15rem;

    margin: 0;
    min-height: 17px;
    padding-top: 3px;
    padding-bottom: 2px;
    padding-left: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;

    color: #E01E5A;
}


.textInputWrapperDefault {

    .textInput {
        border-radius: 12px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
    }
}

.textInputWrapperLarge {

}

.textInputWrapperStatusError.textInputWrapperLarge {
    .textInput {
        color: #E01E5A;
    }
}

.textInputWrapperStatusError {
    .textInput {
        border: 1px solid #E01E5A;
    }
}