.navigation {
  
  nav {
    box-sizing: border-box;

    .menuList {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        align-items: center;
        margin-top: 30px;
        
        a {
          cursor: pointer;
          padding: 0;

          font-size:  14px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          line-height: 16px;

          border: none;
          background-color: transparent;
        }
        
        button {
          cursor: pointer;
          padding: 0;

          font-size:  14px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          line-height: 16px;

          border: none;
          background-color: transparent;
        }

        a,
        button {
          text-transform: capitalize;
        }

        svg,
        img {
          display: flex;
          align-self: center;
          margin-right: 20px;
          width:  18px;
        }
        
        &:first-of-type {
          margin-top: 0;
        }
      }

    }
  }

  button:disabled {
    color: #B9BDC8;
    cursor: not-allowed !important;
  }

  .activeMenu {
    position: relative;

    a,
    button {
      font-weight: 500;
      color: rgb(220, 1, 255);
      cursor: pointer;
    }

    img {
      filter: drop-shadow(3px 4px 6px rgba(220, 1, 255, 0.3));
    }

    &::before {
      content: '';
      position: absolute;
      width:   4px;
      height: 40px;
      left:  -43px;

      background: linear-gradient(95deg, #DC01FF 13.06%, #3736FF 99.99%);
      box-shadow: 2px 0px 15px #DE4AF5;
      border-radius: 0px 6px 6px 0px;
    }
  }
}

.disabled {
  svg {
    fill: #B9BDC8 !important;
  }
}