.FilterDropdownWrapper {
    position: relative;
    display: flex;
}
.FilterDropdownControlWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px 17.5px;
}
.FilterDropdownControlFilterIcon {
    display: flex;
    svg {
        transform: rotate(0deg);
        transition: transition 2s;
    }
}
.FilterDropdownControlIcon {
    display: flex;
    align-items: center;
    margin-top: 2px;
}
.FilterDropdownControlIconTurned {
    svg {
        transition: transition 2s;
        transform: rotate(180deg);
    }
}
.FilterDropdownControl {
    background: #F4F6FA;
    border: none;
    height: 40px;
    border-radius: 12px;
    padding: 12.0492px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    cursor: pointer;
}

.FilterDropdownControlText {
    margin: 0;
    padding: 0;
}

.FilterDropdownControlNumberOfActiveFilters {
    position: absolute;
    right: -5px;
    top: -5px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 110%;
    background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
    border-radius: 50%;
    height: 18px;
    width: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    /* classic-white */

    color: #FFFFFF;
}

.FilterDropdownContentWrapper {
    top: 55px;
    width: calc(100% - 40px);
    left: 0px;
    position: absolute;
    display: flex;
    overflow: hidden;
}

.FilterDropdownContent {
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: white;
    border: 1px solid #DFE3EC;
    color: #d5dae4;
    border-radius: 12.0492px;
    padding-bottom: 15px;
    box-sizing: border-box;
    min-height: 300px;
    max-height: 550px;
    overflow: hidden;
    overflow-y: auto;
}