.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;

  height: calc(100vh - 40px);
  width: 220px;
  padding: 43.5px 0 0 42.5px;

  ul,
  ol {
    padding: 0;
    margin:  0;
    list-style-type: none;
  }

  .logo {
    min-width: 112px;
    min-height: 25px;

    margin-bottom: 55px;
  }

  hr {
    border: none;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    margin: 30px 0;
    width: 180px;
    height: 1px;
    background: rgba(185, 189, 200, 0.3);
  }

  .link {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    
    margin-top: auto;
    white-space:nowrap;

    margin-right:  55px;
    margin-bottom: 42px;
    padding:  10px 15px;

    width: 145px;
    height: 36px;

    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    border: 1px solid #dc01ff33;
    border-radius: 10px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;

    width: 1px;
    height: 91.5vh;
    background: #b9bdc84d;
    transform: translateY(-50%);
  }
}