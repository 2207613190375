.wrapper {
  position: relative;
  width: 100%;
  display: flex;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

.button {
  background: none;
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    background: #F4F6FA;
  }

  svg,
  img {
    width:  16px;
    height: 16px;
  }
}

.dropdown {
  position: absolute;
  left: 0;
  top:  100%;
  z-index: 7;
  width: 100%;
  max-height: 494px;
  overflow-y: auto;

  padding: 4px;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #DFE3EC;
  backdrop-filter: blur(8.03279px);
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  color: #1A1D21;

  > button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    padding: 10px 12px;
    border: none;
    border-radius: 6px;

    text-align: left;

    cursor: pointer;

    &[class~=active] {
      position: relative;
      background: pink;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &:hover {
      background: #F4F6FA;
    }
  }
}

.fallback {
  display: block;
  margin-top: 5px;
  padding: 10px 12px;
}

.search {
  width: 100%;
  padding: 11px 12px;
  padding-left: 45px;
  border: 1px solid #DFE3EC;
  border-radius: 8px;

  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  color: #7A7E89;

  &:hover,
  &:focus {
    outline: none;
  }

  &Wrapper {
    position: relative;
    margin-bottom: 8px;

    &[class~=no-result] {
      margin-bottom: 0;
    }

    img,
    svg {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);

      pointer-events: none;
      user-select: none;
    }
  }
}