.btnBlock {
  position: relative;
  transition: transform 0.2s ease-in-out;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.btn {
  white-space: nowrap;
  max-width: 159px;
  background: linear-gradient(115.74deg, #dc01ff 13.06%, #3736ff 99.99%);
  padding: 11px 20px;
  border: 1px solid rgba(217, 111, 219, 0.7);
  backdrop-filter: blur(10px);
  // max-width: 163px;
  height: 40px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: box-shadow 0.6s ease-in-out;
}
.btn:disabled,
btn[disabled] {
  white-space: nowrap;
  max-width: 159px;
  background: white;
  padding: 11px 20px;
  border: white;
  // max-width: 163px;
  height: 40px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.starLeft {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 45px;
  left: -7px;
  z-index: 3;
  opacity: 0;
}
.starTop {
  position: absolute;
  top: 7px;
  left: 88px;
  width: 20px;
  height: 20px;
  z-index: 3;
  opacity: 0;
}
.starBottom {
  animation: 3s linear 0s normal none infinite running stareffct;

  opacity: 0;
  position: absolute;
  top: 54px;
  left: 62px;
  width: 15px;
  height: 15px;
  z-index: 3;
}

.btnBlock:hover {
  transform: translateY(-1px);
}
.btn:hover {
  background: linear-gradient(110.12deg, #dc01ff 16.7%, #3736ff 148.66%);
  box-shadow: 0px 15px 40px rgba(220, 1, 255, 0.4);
}
