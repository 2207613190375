.anchorWrapper {
    position: relative;
    height: 1px;
}

.anchorBlock {
    position: absolute;
    margin-top: -80px;
}

@media screen and (max-width: 499px) {
    .anchorBlock {
        margin-top: -70px;
    }
}