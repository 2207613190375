.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapperTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  font-weight: 500;
  font-size: 32px;
  font-family: 'Subjectivity';
  margin-top: 200px;
}

.wrapperBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 41px;
}

.wrapperCenterRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.title {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-family: 'Subjectivity';
  color: #B9BDC8;
  font-weight: 400;
  font-size: 16px;
}

@media screen and (max-width: 566px) {

  .title {

    font-size: 12px;

  }

  .smallStartImage {
    margin-top: 200px;
    margin-left: 277px;
    z-index: -1;
    position: absolute;
  }

}
.smallStartImage {
  margin-top: 200px;
  margin-left: 277px;
  z-index: -1;
  position: absolute;
}

.littleStar {
  z-index: -1;
  position: absolute;
  margin-top: 166px;
  margin-left: 53px;
  width: 50px;
  height: 50px;
}
