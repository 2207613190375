.subnavPanelWrapper {
  display: flex;
  flex-direction: column;
  width: 165px;

  button {
    padding: 0;
    background-color: transparent;
    border: none;
    width: fit-content;

    font-family: 'Inter';
    font-style: normal;
    font-size:   14px;
    line-height: 17px;

    &[disabled] {
      color: #B9BDC8;
      cursor: not-allowed;
    }
  }

  .bookmarksList {
    display: flex;
    align-self: center;
    flex-direction: column;

    li {
      display: flex;
      align-items: center;

      &:not(first-child) {
        margin-top: 20px;
      }

      &:first-child {
        margin-top: 0;
        margin-left: 0;
      }

      &:not(first-child) {
        margin-left: 35px;
      }
    }

    svg {
      margin-right: 20px;
    }
  }
}