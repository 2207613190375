.wrapper {
  position: relative;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

.button {
  background: #F4F6FA;
  backdrop-filter: blur(8.03279px);
  border: none;
  border-radius: 12.0492px;
  padding: 10.5px 12px;
  display: flex;
  align-items: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #1A1D21;

  cursor: pointer;
}

.filterIcon {
  margin-right: 8px;
}

.arrowIcon {
  margin-left: 8px;

  &[class~=open] {
    transform: rotate(180deg);
    position: relative;
    top: -1px;
  }
}

.count {
  position: absolute;
  right: -4px;
  top:   -2px;
  width:  18px;
  height: 18px;
  border-radius: 50%;
  background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 110%;
  text-align: center;
  color: #FFFFFF;
}

.dropdown {
  position: absolute;
  z-index: 3;
  left: 0;
  top:  100%;
  margin-top: 12px;
  min-width: 354px;
  padding-bottom: 0;
  background: #FFFFFF;
  border: 1px solid #DFE3EC;
  border-radius: 12.0492px;
  backdrop-filter: blur(8.03279px);

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #1A1D21;

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 18px;
    border-bottom: 1px solid rgba(185, 189, 200, 0.3);
  }

  button {
    padding: 7.5px 10px;
    border-radius: 10px;

    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;

    cursor: pointer;
  }
}

.filters {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 12px 18px;
    padding-bottom: 0;

    &[class~=active] {
      background: #F8F8F9;
      padding: 12px 18px;
    }

    label {
      display: flex;
      align-items: center;
    }
  }
}