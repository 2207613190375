.wrapper {
  margin-top: 1.4em;

  font-family: 'Inter';
  font-style: normal;
}

.title {
  margin: 0;
  margin-bottom: .5em;

  font-size: 1.25em;
}

.subtitle {
  margin: 0;

  font-size: 14px;

  a {
    color: #DC01FF;
  }
}