.contentBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  width:  100%;
  min-height: 120px;

  padding: 39.5px 69px 40px 30px;
  box-sizing: border-box;

  .title {
    display: flex;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    margin-bottom: 30px;
  }

  .headline {
    display: flex;
    align-items: baseline;
    margin-bottom: 5px;

    > :first-child {
      margin: 0;

      font-family: 'Subjectivity';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 110%;
      color: #1A1D21;
    }

    img {
      align-items: center;
      margin-left: 14px;
      width:  24px;
      height: 24px;
    }
  }

  .subtitle {
    display: flex;

    font-size: 0.875em;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 17px;
    white-space: nowrap;
    color: #7a7e89;
  }
}

.lastUpdated {
  position: absolute;
  right: 15px;
  top:   15px;

  font-family: 'Inter';
  font-size: 12px;
  color: #B9BDC8;
  line-height: 130%;
  text-align: right;
}

@media screen and (max-width: 1240px) {
  .subtitle {
    white-space: normal !important;
  }
}