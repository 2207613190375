.sort-button {
  display: flex;
  background: none;
  border: none;
  padding: 0;

  cursor: pointer;

  &.DESC {
    transform: scaleY(-1) translateY(-.3px);
  }
}