.tableWrap {
  position: relative;
  z-index: 0;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  .addConditionButton {
    width:  20px;
    height: 20px;
    background: none;
    padding: none;
    border: none;
    cursor: pointer;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    thead {
      background: #f4f6fa;
    }

    tbody::before {
      content: '';
      display: block;
      height: 10px;
    }

    tr {
      position: relative;
      background: inherit;

      &:hover {
        background: rgb(244, 246, 250);
      }

      &::before {
        content: '';
        position: absolute;
        width:  1040px;
        height: 1px;
        left: 50%;
        top:  100%;
        background: rgba(185, 189, 200, 0.1);
      
        transform: translate(-50%, -50%);
      }

      &:last-of-type::before {
        visibility: hidden;
        height: 0;
      }
    }

    th,
    td {
      &:last-child {
        min-width: unset;
      }
    }

    th {
      padding: 14px 7px 14px 15px;

      font-size: 0.75rem;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      line-height: 110%;

      .headerTh {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 19px;
        margin: 14px 7px 14px 15px;

        span {
          min-width: 40px;
          max-width: 200px;
          white-space: nowrap;
        }
      }

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 1;

        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    td {
      font-size: 0.75rem;
      padding: 15px;

      &:first-child {
        padding-left: 34.5px;
      }

      input {
        font-size: 12px !important;
      }

      &:first-child {
        width: 1ch;
        border-top-left-radius:    10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius:    10px;
        border-bottom-right-radius: 10px;
      }
    }

    .walletInfo {
      p {
        margin: 0;
        font-weight: 400;
        color: #7a7e89;
        line-height: 110%;
      }
    }

    .assetsBlock {
      width: 180px;
    }

    .sourseWrapper {
      display: flex;
      flex-direction: column;
    }
  }
}

.conditionsEditingWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.conditionsEditingRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > * + * {
    margin-left: 12px;
  }
}

.buttons {
  margin-left: auto;
  align-self: flex-end;
  display: flex;
  align-items: center;

  > * {
    box-sizing: content-box;

    cursor: pointer;
  }

  .cancel {
    width: 46px;
    height: 17px;
    padding: 5px 8px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    background-color: transparent;
    border: none;
    color: #7A7E89;
  }

  .save {
    width: 33px;
    height: 17px;

    border: 1px solid rgba(217, 111, 219, 0.5);
    border-radius: 10px;
    background: #F9FAFC;
    margin-left: 15px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    &[disabled] {
      cursor: not-allowed;
      opacity: .5;
    }
  }
}

.addButton {
  margin-left: auto;
  background: transparent;
  border: none;
  padding: 5px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  cursor: pointer;

  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
  }

  img,
  svg {
    margin-right: 13px;
  }
}

.conditionsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: .45em;
}

.subrow {
  background: pink;
}

dl {
  margin: 0;
  display: flex;

  dt {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #7A7E89;
  }

  dd {
    margin: 0;
  }
}