.labelBlock {
  display: inline-block;
  border-radius: 5px;
  margin:  0;
  padding: 0;
  padding: 4px 6px;
  max-width: 60px;

  font-family: 'Inter';
  font-size: 0.75rem;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fluid {
  max-width: unset;
}