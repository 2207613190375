.tableHead {
  display: flex;
  margin-bottom: 8px;

  padding: 13.5px 15px;
  border-radius: 10px;
  background: #F4F6FA;

  font-family: 'Inter';
  font-size: 12px;
  line-height: 110%;
  color: #1A1D21;
}