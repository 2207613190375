.wrapper {
  position: relative;
  .line {
    position: absolute;
    z-index: 2;
    width: calc(100% - 30px);
    height: 1px;
    background: rgba(185, 189, 200, 0.1);
    left: 15px;
    bottom: -0.1em;
  }
  .string_bold {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    color: #1a1d21;
  }
  .textBold {
    font-weight: bold;

    overflow: hidden;
    text-overflow: ellipsis;
  }
  .labels_color {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.walletInfoP {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 140px;
  font-weight: bold;
}

.address {
  display: flex;
  align-items: center;

  &__copyButton {
    margin-left: .2em;
  }
}
