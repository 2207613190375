.wrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 500px;
  backdrop-filter: blur(20px);
  background: radial-gradient(
      161.84% 161.84% at 50% 50%,
      rgba(101, 101, 101, 0.126) 0%,
      rgba(101, 101, 101, 0.082) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border: 1px solid rgba(242, 242, 242, 0.15);
  border-radius: 30px;
  margin-bottom: 20px;
}
.titles {
  width: 400px;
  height: 243px;
}
.contentImg {
  display: flex;
  padding: 60px;
}



@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  .contentImg {
    margin-top: 40px;
  }
  .titles {
    width: 100%;
  }
  .contentImg {
    padding: 40px 20px 20px;
  }
  .contentImg {
    padding: 80px 100px 20px;
  }
}

@media screen and (max-width: 450px) {
  .contentImg {
    padding: 40px 30px 20px 20px;
  }
}
