.showPopup {
  display: block;
  position: fixed;
  right: 36px;
  bottom: 40px;
  width: 312px;
  // height: 95px;
  border: 1px solid rgba(220, 1, 255, 0.2);
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  background: #ffffff;
}
.noShowPopup{
  display: none;
}
.overlay {
  display: flex;
  width: calc(100% - 28px);
  height: 100%;
  flex-direction: column;
  margin: 14px;
  .header{
    font-size: 14px;
    font-family: 'Subjectivity';
    font-weight: 500;
    color: #1A1D21;
    margin: 0;
    margin-left: 8px;
    margin-top: 4px;
  }
  .message{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #7A7E89;
    margin: 0;
    margin-bottom: 8px;
    padding-right: 40px;
  }
  .headerWrap{
    display: flex;
    flex-direction: row;
    justify-content:start;
    height: 16px;

  }
  .headerWrapWithClose{
    width: 100%;
    height: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;

  }
  .wrapMessage{
    margin-left: 24px;
    display: flex;
    flex-direction: column;

  }
}
.closeButton {
  width: 16px;
  height: 16px;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  svg{
    margin: auto;
    path{
      fill: #7A7E89;
    }
  }
  &:hover{
    path{
      fill: #DC01FF;
    }
  }
}
.reload {
  width: fit-content;
  border: none;
  padding: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  background-color: #3736FF;
  background-repeat: repeat;
  background-image:linear-gradient(115deg, #DC01FF 1%, #3736FF 26%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;

  cursor: pointer;
}