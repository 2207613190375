.wrapper {
  z-index: 1;
  display: flex;
  background: white;
  border: 1px solid #DFE3EC;
  border-radius: 10px;

  .filterButton {
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    padding: 7.5px 15px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #7A7E89;

    cursor: pointer;

    img,
    svg {
      margin-top:  -1px;
      margin-right: 10px;
    }

    &:hover,
    &:focus,
    &[class~=active] {
      background: #F4F6FA;
      color: #1A1D21;  
    }
  }
}

.lastFilter {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.customDate {
  position: relative;
  z-index: 12;

  button {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.dateRangeWrapper {
  box-sizing: border-box;
  z-index: 12;
  margin-top: 8px;
  position: absolute;
  top: 100%;
  padding: 10px;
  background-color: white;
  border: 1px solid #DFE3EC;
  border-radius: 12px;

  .rdrCalendarWrapper .rdrDateDisplayWrapper {
    display: none;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > * {
      box-sizing: content-box;
  
      cursor: pointer;
    }

    button {
      padding: 7.5px 12px;
      font-size: 14px;
    }
  
    .cancel {
      width:  46px;
      height: 17px;
  
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      background-color: transparent;
      border: none;
      color: #7A7E89;
    }
  
    .save {
      width:  33px;
      height: 17px;
      margin-left: 15px;
      font-size: 14px;
      font-weight: 500;
  
      color: white;
      background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
      border: 1px solid rgba(217, 111, 219, 0.7);
      border-radius: 6px;

  
      &[disabled] {
        cursor: not-allowed;
        opacity: .5;
      }
    }
  }
}

.dateRange {
  // секция с вводом своей даты
  [class~=rdrDateDisplayWrapper] {
    display: none;
  }

  // общая ширина календаря
  [class~=rdrMonth] {
    width: 19em !important;
  }

  // секция с селектами и стрелками
  [class~=rdrMonthAndYearWrapper] {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 0;
    height: 45px;
  }
  [class~=rdrMonthPicker] {
    margin: 0;

    select {
      padding: 1px 2px;
      text-align: start;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
  [class~=rdrYearPicker] {
    select {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #1A1D21;
      text-align: left;
    }
  }
  [class~=rdrNextPrevButton] {
    position: absolute;
    right: 0;
    background: none;
  }
  [class~=rdrPprevButton] {
    position: absolute;
    margin: 0;
    right: 40px;
  }
  [class~=rdrMonthAndYearPickers] {
    position: absolute;
    left: 0;
    margin-left: 13px;
  }
}