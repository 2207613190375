.slider {
  position: relative;
}
.carousel {
  overflow: hidden;
}
.innerCarousel {
  display: flex;
  min-width: 2159px;
}
