.wrapper {
  max-width: 1440px;
  height: 730px;
  justify-content: center;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 38px;
}

.bg {
  margin-top: 0px;
  z-index: -1;
  position: absolute;
}

.bgMobile {
  width: 120%;
  margin-top: 0px;
  z-index: -1;
  position: absolute;
  display: none;
  margin-top: -160px;
}

.bgTwo {
  margin-top: 40px;
  width: 90%;
  height: auto;
  image-rendering: initial;
  image-rendering: -webkit-optimize-contrast;
}


.wrapperImg {
  z-index: -1;
  display: flex;
  max-width: 1440px;
  flex-direction: row;
  justify-content: center;
}

@media screen and  (min-width: 1440px) {
  .bg {
    width: 230%;
    margin-left: -40%;
  }
  
  
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .wrapper {
    max-width: 100%;
    width: 100%;
  }
  
}


@media screen and (max-width: 768px) {
  .bg {
    margin-top: -200px;
  }
}

@media screen and (max-width: 499px) {
  .wrapper {
    height: auto;
    padding-bottom: 50px;
  }
  .bgTwo {
    width: auto;
    height: 280px;
    padding-top: 0px;
    // image-rendering: pixelated;
  }
  .wrapperImg {
    width: 100%;
    overflow-x: hidden;
    position: relative;
    padding-top: 100px;
    margin-top: -90px;
  }
  .bg {
    display: none;
  }
  .bgMobile {
    display: block;
  }

}



.rightButtonStar {
  position: absolute;
  height: 20px;
  width: 20px;
  right: -14px;
  top: -15px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0L10.624 7.54756C10.7046 8.52203 11.478 9.29538 12.4524 9.37595L20 10L12.4524 10.624C11.478 10.7046 10.7046 11.478 10.624 12.4524L10 20L9.37595 12.4524C9.29538 11.478 8.52203 10.7046 7.54756 10.624L0 10L7.54756 9.37595C8.52203 9.29538 9.29538 8.52203 9.37595 7.54756L10 0Z' fill='white'/%3E%3C/svg%3E%0A");
}