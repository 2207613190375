.wrapper {
  position: relative;
  z-index: 3001;
  width: 260px;
  display: flex;
  justify-content: flex-end;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

.button {
  display: flex;
  align-items: center;
  background: #F4F6FA;
  padding: 12px 10px;
  border: none;
  border-radius: 10px;

  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  color: #1A1D21;

  cursor: pointer;

  img,
  svg,
  [role=img] {
    margin-right: 10px;
  }
}

.dropdown {
  position: absolute;
  left: 0;
  top:  -20px;
  width: 100%;
  max-height: 494px;
  overflow-y: auto;

  padding: 4px;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #DFE3EC;
  backdrop-filter: blur(8.03279px);
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  color: #1A1D21;

  > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    border-radius: 6px;

    cursor: pointer;

    &[class~=active] {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    
    &:hover {
      background: #F4F6FA;
    }
  }
}

.search {
  width: 100%;
  padding: 11px 12px;
  padding-left: 45px;
  border: 1px solid #DFE3EC;
  border-radius: 8px;

  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  color: #7A7E89;

  &:hover,
  &:focus {
    outline: none;
  }

  &Wrapper {
    position: relative;
    margin-bottom: 8px;

    &[class~=no-result] {
      margin-bottom: 0;
    }

    img,
    svg {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);

      pointer-events: none;
      user-select: none;
    }
  }
}