.checkboxLabel {
    width: 100%;
    display: flex;
    margin: 8px 0px;
  }
  /* The container */
  .containerCheckbox {
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    

    line-height: 20px;
    color: #666666;
    display: flex;
    padding-left: 22px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #1A1D21;

  }

  .containerCheckboxDisabled {
    cursor: not-allowed;
    opacity: 0.5;
    .checkmark {
      background-color: #f0f0f0;
    }
    input {
      cursor: not-allowed;
    }

  }
  .containerCheckbox.containerCheckboxDisabled:hover input ~ .checkmark {
    background-color: #f0f0f0;
    
  }
  
  /* Hide the browser's default checkbox */
  .containerCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom checkbox */
 .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid rgba(185, 189, 200, 0.5);
    box-sizing: border-box;
    border-radius: 50%;
    transition: all 0.3s ease;
    
  }
  
  /* On mouse-over, add a grey background color */
  .containerCheckbox:hover input ~ .checkmark {
    transition: all 0.3s ease;
    background-color: #f4f4f4;
  }

  
  /* When the checkbox is checked, add a blue background */
  .containerCheckbox input:checked ~ .checkmark {
    /* background-color: #2196f3; */
   
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .containerCheckbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .containerCheckbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .containerCheckbox .checkmark:after {
    content: "";
    left: 3px;
    top: 3px;
    width: 8px;
    height: 8px;


    /* gradient */

    background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
    border-radius: 16px;
  }
  