.progressWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .progress {
    position: relative;
    height:  3px;
    flex-grow: 1;

    .trail,
    .filled {
      position: absolute;
      left: 0;
      top:  0;
      height: 100%;

      box-sizing: border-box;

      border-radius: 4px;
    }

    .trail {
      width: 100%;
      border: 1px solid rgba(217, 111, 219, 0.5);
    }

    .filled {
      background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
      transform-origin: left;
    }

  }
  .circleWrapper {
    width:   100%;
    height:  100%;
    display: flex;

    svg {
      transform: rotate(-90deg) scaleY(-1);
    }
  }

  .label {
    margin-left: 10px;
    min-width:  2.7em;

    font-size:   12px;
    line-height: 15px;
    font-weight:  400;

    &Gray {
      color: #7A7E89;
    }
  }
}