.checkboxMultitude {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    
}

.checkboxMultitudeHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 120%;
        margin: 0;
        /* identical to box height, or 14px */


        /* dark */

        color: #1A1D21;
    }
    a {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        cursor: pointer;
        text-decoration-line: underline;
        color: #003BD2;
        transition: all 0.3s ease;

        &:hover {
            text-decoration-line: none;
        }
    }
}

.checkboxMultitudeCheckboxList {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
        width: calc(50% - 40px);
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}