.options {
  overflow: hidden;
  min-width: 200px;
  max-width: 200px;
  background: white;
  border: 1px solid #DFE3EC;
  border-radius: 12.0492px;
  backdrop-filter: blur(8.03279px);

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  button {
    background: none;
    width: 100%;
    border: none;
    padding: 10px;
    display: flex;
    align-items: center;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;

    cursor: pointer;

    &:hover {
      background-color: #f4f4f5;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }
}

.iconWrapper {
  margin-right: 12px;
  width:  10px;
  height: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}