.mainContainer {
  z-index: 10;
  position: fixed;
  top:  50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:  100%;
  height: 120%  ;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CohortAddPopup {
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 395px;
  padding: 30px;
  background: #FFFFFF;
  outline: 1px solid #DFE3EC;
  border-radius: 10px;

  &::after {
    content: "";
    position: fixed;
    z-index: -1;
    top:  50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:  100vw;
    height: 120vh;
    backdrop-filter: blur(3px);
  }

  h1 {
    margin: 0;
    margin-bottom: 20px;

    font-family: 'Subjectivity';
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
  }

  .subtitle  {
    margin-bottom: 30px;

    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }

  input {
    box-sizing: border-box;
    height: 40px;
    border-radius: 12px;
    padding: 10px 12px;
    background: white;
    border: 1px solid #DFE3EC;
    
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;

    &:focus {
      outline: 1px solid #DFE3EC;
    }

    &::placeholder {
      color: #7A7E89;
    }
  }

  .detailedInfoContainer {
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &__item {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;

      span {
        &:first-of-type {
          margin-bottom: 10px;
          font-family: 'Inter';
          font-weight: 400;
          font-size: 12px;
          line-height: 110%;
    
          color: #7A7E89;
        }

        &:last-of-type {
          font-family: 'Inter';
          font-weight: 400;
          font-size: 12px;
          line-height: 130%;
        }
      }
    }
  }

  .buttonsContainer {
    display: flex;
    margin-left: auto;
    margin-top: 30px;

    button {
      padding: 11px 20px;
      background-color: transparent;
      border-radius: 10px;
      border: none;
      cursor: pointer;
    }

    .confirmButton {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      
      color: #7A7E89;
    }

    .saveButton {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 16px;
      line-height: 110%;
      background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
      border: 1px solid rgba(217, 111, 219, 0.7);
      color: white;
    }

    .disabled {
      opacity: .5;
      user-select: none;
      cursor: not-allowed;
    }
  }

  .extraInfo {
    margin-bottom: 0;

    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}