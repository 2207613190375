.authPage {
	display: flex;
	justify-content: center;
	background-color: #fff;
	height: 100vh;

	.wrapper {
		padding-top: 43px;
		min-width:  347px;
		width: 			347px;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		> svg, img {
			flex-shrink: 0;
		}
	
		.content {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 5em;
			margin-top: min(5em, 3vh);

			h1,
			h2 {
				margin:  0;
				padding: 0;
				text-align: center;
			}
	
			h1 {
				margin-bottom: 20px;

				font-family: 'Subjectivity';
				font-style: normal;
				font-weight: 500;
				font-size: 40px;
				line-height: 110%;
				color: #1A1D21;
			}
	
			h2 {
				margin-bottom: 30px;
				text-align: center;
				width: 347px;

				font-family: 'Inter';
				font-size: 18px;
				font-weight: 400;
				line-height: 140%;
				color: #7A7E89;
	
				a {
					background-image: linear-gradient(115.74deg, #dc01ff 13.06%, #3736ff 99.99%);
					background-clip: text;
					color: transparent;
					transition: all 0.3s ease;
					border-bottom: 1px solid linear-gradient(115.74deg, #dc01ff 13.06%, #3736ff 99.99%);
	
					&:hover {
						background-position: 10px;
					}
				}
			}
			
			.inputsContainer {
				margin-bottom: 40px;
				width: 100%;
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				> * {
					width: 98%;

					+ * {
						margin-top: 20px;
					}
				}

				.error {
					margin:  0;
					padding: 0;
					position: absolute;
					bottom: -26px;
			
					font-family: 'Inter';
					font-size: 14px;
					font-weight: 400;
					line-height: 140%;
					color: #E01E5A;
					text-align: center;
				}
			}

			.btnPass {
				border: none;
				background: none;
			}

			.star {
				position: absolute;
				height: 16px;
				width:  16px;

				right: -12px;
				top: -15px;
			}
	
			.errorFormText {
				margin:  0;
				margin-top: 10px;
				padding: 0 20px;

				font-family: 'Inter';
				font-size: 14px;
				font-weight: 400;
				line-height: 140%;
				color: #f2114d;
				text-align: center;

				a {
					font-weight: bold;
				}
			}
		}

		.forgotButton {
			margin:  0;
			padding: 0;
			margin-top: 35px;

			font-family: 'Inter';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 140%;

			background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			border: none;
			cursor: pointer;
		}
	}
}