.answerBlock {
  position: relative;
  width: 630px;
  height: 650px;
  background: radial-gradient(
    161.84% 161.84% at 50% 50%,
    rgba(101, 101, 101, 0.126) 0%,
    rgba(101, 101, 101, 0.082) 100%
  );
  border: 1px solid rgba(242, 242, 242, 0.15);
  border-radius: 30px;
  margin-bottom: 20px;
}
.answerTitles {
  padding: 80px 80px 0 80px;
  h1 {
    color: white;
    font-weight: 500;
    font-size: 40px;
    margin: 0 !important;
  }
  h2 {
    margin: 20px 0 0 0 !important;
    margin: 20px 0 20px 0;
    font-weight: 400;
    font-size: 18px;
    color: #b9bdc8;
  }
  button {
    margin-top: 20px;
  }
}
.primaryTitle {
  width: 400px;
  height: 88px;
}
.primarySubtitle {
  width: 400px;
  height: 75px;
}

.secondaryTitle {
  width: 400px;
  height: 44px;
}
.secondarySubtitle {
  width: 400px;
  height: 75px;
}
.star {
  position: absolute;
  top: 50px;
  left: 45px;
}
@media screen and (min-width: 769px) and (max-width: 1440px) {
  .primaryTitle {
    width: 100%;
    height: auto;
  }
  .primarySubtitle {
    width: 100%;
    height: auto;
  }
  .secondaryTitle {
    width: 100%;
    height: auto;
  }
  .secondarySubtitle {
    width: 100%;
    height: auto;
  }
  .answerTitles {
    padding: 60px 60px 0 60px;
  }
  .star {
    top: 32px;
    left: 30px;
    transform: scale(0.7);
  }
}
@media screen and (max-width: 768px) {
  .primaryTitle {
    width: 90%;
    height: auto;
  }
  .secondarySubtitle {
    height: auto;
  }
}

@media screen and (max-width: 450px) {
  .answerTitles {
    display: flex;
    flex-direction: column;

    padding: 40px;
  }
  .primaryTitle {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 28px !important;
    line-height: 110% !important;
  }

  .answerTitles h1 {
    width: 100%;
    height: auto;
  }
  .star {
    top: 20px;
    left: 10px;
    width: 30px;
    height: 30px;
  }
  .primarySubtitle {
    width: 100%;
    height: auto;
  }
  .secondarySubtitle {
    width: 100%;
  }
  
  .answerTitles {
    padding: 40px 30px;
  }
  .secondaryTitle {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 28px !important;
    line-height: 110% !important;
  }
  
  
}