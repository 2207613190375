.contextMenuWrapper {
  position: relative;
  box-sizing: border-box;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  .addData {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width:  19px;
    
    border: none;
    border-radius: 5px;
    background: transparent;

    &:hover {
      background-color: #f4f4f5;
    }
  }

  button {
    display: flex;
    align-items: center;
    margin:  0;
    padding: 0;
    
    border: none;
    background-color: transparent;

    cursor: pointer;

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }


  .popup {
    z-index: 10;
    overflow: hidden;
    position: absolute;
    right:  0;
    top: 100%;
    width: 200px;
    margin-top: 10px;

    background: #fff;
    border: 1px solid #DFE3EC;
    border-radius: 12px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      
      li {
        cursor: pointer;

        &:hover {
          background-color: #f4f4f5;
        }

        button {
          width: 100%;
          padding: 10px;
        }
      }
      
      .contract {
        position: relative;

        img {
          width:  16px;
          height: 16px;
        }
        
        button {
          padding: 10px;
          padding-bottom: 12px;
        }
        
        &::after {
          content: '';
          position: absolute;

          height: 1px;
          width: 186px;

          left:   50%;
          bottom: 0;
          transform: translateX(-50%);

          background-color: #b9bdc81a;
        }
      }

      li {
        display: inline-flex;
        align-items: center;
        width: 100%;

        img {
          margin-right: 17px;
          height: 17px;
          width:  17px;
        }

        span {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;

          color: #1A1D21;
        }
      }
    }
  }
}