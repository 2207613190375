.button {
  padding: 5px 10px;
  background: #F4F6FA;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8.03279px);
  border: none;
  border-radius: 5px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #003BD2;

  cursor: pointer;

  &[disabled] {
    font-weight: 400;
    color: #B9BDC8;
    
    cursor: not-allowed;
    
    svg {
      fill: #B9BDC8;
    }
  }
}

.display {
  color: #1A1D21;
}