.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1440px;
  height: 790px;
}
.bgImage {
  z-index: -1;
  max-width: 1440px;
  position: absolute;
  top: 75px;
}

.infoBlocks {
  display: flex;
  margin-top: 40px;
  margin-left: 80px;
}


@media screen and (min-width: 769px) and (max-width: 1440px) {
  
  .infoBlocks {
    margin-left: 0px;
    padding: 0px 20px;
  }
  .wrapper {
    max-width: 100%;
    width: 100%;

  }
}

@media screen and (min-width: 700px) and (max-width: 768px) {
  .wrapper {
    height: 1341px;
  }
  .infoBlocks {
    flex-direction: column;
    margin-left: 40px;
  }
}




.nodeSearch {
  div {
    svg {
      width: auto !important;
      height: auto !important;
      transform: scale(0.36) !important;
      margin-left: -420px;
    }
  }
}
.linkSessionВeta {
  div {
    svg {
      width: auto !important;
      height: auto !important;
      transform: scale(0.32) !important;
      margin-left: -360px;
    }
  }
}
.infiniteIntegrations {
  div {
    svg {
      width: auto !important;
      height: auto !important;
      transform: scale(0.32) !important;
      margin-left: -410px;
    }
  }
}

@media screen and (max-width: 499px) {
  .wrapper {
    height: auto;
  }
  .infoBlocks {
    margin-top: 30px;
    margin-left: 0px;
  }

  .nodeSearch {
    div {
      svg {
        margin-left: -440px;
      }
    }
  }
  .linkSessionВeta {
    div {
      svg {
        margin-left: -390px;
      }
    }
  }
  .infiniteIntegrations {
    div {
      svg {
        margin-left: -420px;
      }
    }
  }

}

@media screen and (max-width: 380px) {
  .nodeSearch {
    div {
      svg {
        margin-left: -460px;
      }
    }
  }
  .linkSessionВeta {
    div {
      svg {
        margin-left: -400px;
      }
    }
  }
  .infiniteIntegrations {
    div {
      svg {
        margin-left: -440px;
      }
    }
  }
}