.btn-list {
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 10;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li:first-child {
    margin-top: 30px;
  }
  li:not(first-child) {
    margin-top: 10px;
  }
  button {
    font-family: 'Inter';
  }
}
.button-default {
  cursor: pointer;
  display: inline-block;
  max-width: 84px;
  height: 25px;
  padding: 7px 11px;
  text-align: center;
  background: #1a1d21;
  border: 1px solid rgba(122, 126, 137, 0.4);
  backdrop-filter: blur(7.0765px);
  border-radius: 7.0765px;
  color: white;
  font-weight: 500;
  font-size: 9.9071px;
  line-height: 110%;
  transition: border 0.3s ease-in-out;
  &:hover {
    border: 1px solid #dc01ff;
  }
}

.btn-active {
  cursor: pointer;
  display: inline-block;
  max-width: 84px;
  height: 25px;
  padding: 7px 11px;
  text-align: center;
  background: #1a1d21;
  border: 1px solid #dc01ff;
  backdrop-filter: blur(7.0765px);
  border-radius: 7.0765px;
  color: white;
  font-weight: 500;
  font-size: 9.9071px;
  line-height: 110%;
}

.switch-wrapper {
  display: flex;
  max-width: 464px;
  height: 210px;
  position: relative;
}
@media screen and (min-width: 1051px) and (max-width: 1440px) {
  .switch-wrapper {
    max-width: 100%;
    transform: scale(0.9);
  }
}
@media screen and (min-width: 769px) and (max-width: 1050px) {
  .switch-wrapper {
    max-width: 100%;
    transform: scale(0.65);
  }
}

.slider-wrapper {
  display: flex;
  position: absolute;
  top: -23px;
  left: 40px;
}
.slider {
  width: 450px;
  height: 230px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.slider__slide {
  min-width: 100%;
  position: absolute;
  height: 201px;
  transition: opacity 0.6s;
  opacity: 0;

  img {
    max-width: 412px;
    max-height: 201px;
  }
}

.slide__active {
  cursor: pointer;
  position: relative;
  z-index: -1;
  opacity: 1;
}
.slider__active-image {
  position: absolute;
}

.slider-wallet {
  position: absolute;
  top: 10px;
  left: 10px;
  transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
}
.slider-contracts {
  position: absolute;
  top: 16px;
  left: 24px;
  transition: left 0.3s ease-in-out, top 0.3s ease-in-out;
}
.slider-nft {
  position: absolute;
  top: 25px;
  left: 3px;
  transition: left 0.3s ease-in-out, top 0.3s ease-in-out;
}
.slider-transactions {
  position: absolute;
  top: 25px;
  left: 39px;
  transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
}

@media screen and (max-width: 450px) {
  .switch-wrapper {
    position: relative;
    max-width: 293px;
    height: 127px;
  }

  .slider__slide {
    img {
      max-width: 260px;
      max-height: 127px;
    }
  }
  .slider {
    transform: translateX(-11px);
    max-width: 280px;
  }
  .slider__slide {
    height: 175px;
  }
  .button-default {
    padding: 4px 7px;
    backdrop-filter: blur(4.46796px);
    font-weight: 500;
    font-size: 6.25515px;
    line-height: 110%;
  }
  .btn-active {
    padding: 4px 7px;
    z-index: 0 !important;
    backdrop-filter: blur(4.46796px);
    font-weight: 500;
    font-size: 6.25515px;
    line-height: 110%;
  }
  .slider-wallet {
    position: absolute;
    top: 13px;
    left: 4px;
  }
  .slider-contracts {
    top: 28px;
    left: 14px;
  }
  .slider-nft {
    position: absolute;
    top: 50px;
    left: 0px;
  }
  .slider-transactions {
    top: 49px;
    left: 20px;
  }
} ;
