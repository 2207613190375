.preloader {
  position: absolute;
  left:   -1em;
  right:  -1em;
  top:    -1em;
  height: calc(100%);
  z-index: 3000;

  background: rgba(white, .2);
  backdrop-filter: blur(4px);
  display: flex;

  &[hidden] {
    display: none;
  }

  > * {
    width:  32px !important;
    height: 32px !important;
    margin: auto !important;
  }
}