.modal {
  position: fixed;
  z-index: 3000;
  left: 0;
  top:  0;
  width:  100%;
  height: 100%;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #1A1D21;

  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top:  0;
    width:  100%;
    height: 100%;
    background: white;
    opacity: 0.8;
    backdrop-filter: blur(6px);
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  &Content {
    position: absolute;
    left: 50%;
    top:  50%;
    transform: translate(-50%, -50%);

    background: #FFFFFF;
    border: 1px solid #DFE3EC;
    border-radius: 10px;
    padding: 30px;

    max-width: 936px;
    width: 100%;
    max-height: calc(100vh - 50px);
    overflow-y: auto;

    cursor: initial;
  }
}

.title {
  margin: 0;
  margin-bottom: 20px;

  font-family: 'Subjectivity';
  color: #1A1D21;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
}

.subtitle {
  margin: 0;
}

.search {
  margin-top: 40px;
  margin-bottom: 30px;
  width: 335px;
}

.tableWrap {
  max-height: 300px;
  overflow-y: auto;
}

.footer {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;

  > * {
    padding: 8px 20px;

    + * {
      margin-left: 15px;
    }
  }
}

.cancel {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  background-color: transparent;
  border: none;
  color: #7A7E89;

  cursor: pointer;
}

.save {
  background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
  border: 1px solid rgba(217, 111, 219, 0.7);
  border-radius: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: white;

  text-align: center;

  cursor: pointer;
}