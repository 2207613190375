.addDataDropdownWrapper {
  position: relative;

  * {
    box-sizing: border-box;
  }

  .addData {
    background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;

    border: 1px solid rgba(220, 1, 255, 0.2);
    border-radius: 10px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;

    cursor: pointer;

    img {
      margin-right: 10px;
    }
  }

  .disabled {
    border-color:#DFE3EC;
    background:  #F9FAFC;
    -webkit-text-fill-color: #7A7E89;
    cursor: not-allowed;
  }

  .selection {
    z-index: 1;
    position: absolute;

    display: flex;
    flex-direction: column;
    width: 161.05px;

    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    
    border-radius: 10px;
    background-color: white;
    
    ul {
      padding: 0;
      margin:  0;
      list-style: none;
      
      li {
        border-radius: 5px;

        &:hover {
          background-color: #f3f3f3;
        }

        button {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          padding: 13px 13px;
  
          cursor: pointer;
          border: none;
          background-color: transparent;

          &:disabled {
            cursor: not-allowed;
          }
  
          span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 110%;
          }

          img {
            width: 11px;
            height: 8px;
          }
        }

        .comingSoon {
          display: flex;
          align-items: center;
          padding: 4px 5px;

          color: white;

          width:  86px;
          height: 19px;
          font-size: 10px;

          background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
          border-radius: 5px;

          img {
            height: 11px;
            margin-right: 3px;
          }
        }
      }
    }
  }
}