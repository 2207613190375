.sourceBlock {
  display: flex;
  align-items: center;
  img {
    margin-right: 6px;
  }
  .wrapContent {
    width: 112px;
    display: flex;
    justify-content: space-between;
  }
}
.sourceBlock {
  margin-top: 2px;
}
