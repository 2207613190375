
.title {
  display: flex;
  flex-direction: column;
  margin-top: 65px;
  margin-left: 81px;

  span:first-child {
    font-family: 'Subjectivity';
    color: white;
    font-weight: 500;
    font-size: 60px;
  }

  span:last-child {
    color: white;
    font-weight: 400;
    font-size: 18px;
    margin-top: 30px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .title {
    margin-left: 40px;
  }
}

@media screen and (max-width: 499px) {
  .title {
    margin-left: 40px;
  }
  .title span:first-child {
    width: 90%;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 110%;
  }
  .title span:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin-top: 10px;
  }

}
