.overview-chart-wrapper {

  .recharts-tooltip-wrapper {
    position: relative;
    z-index: 0;
    margin-top: -10ch;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      top: 100%;
      margin-top: -1px;
      width:  2em;
      height: 1em;
      background: 
        url("data:image/svg+xml,%3Csvg width='13' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.5.5h11.293L1.487 10.806a.578.578 0 0 1-.987-.41V.5Z' fill='%23fff' stroke='%23DFE3EC'/%3E%3C/svg%3E") no-repeat center / contain;
    }
  }

  .recharts-tooltip-wrapper,
  .recharts-tooltip-wrapper-right,
  .recharts-tooltip-wrapper-top
  {
    .recharts-default-tooltip {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 5px 11px;
      box-sizing: border-box;
      background: #FFFFFF;
      border:1px solid #dfe3ec;
      border-radius: 10px;
      
      .recharts-tooltip-item-list {
        > :first-child {
          padding-top: 0 !important;
        }
      }

      .recharts-tooltip-item-list,
      .recharts-tooltip-item {
        & > span {
          font-size: 12px;
          color: #7A7E89;
        }
        & > :nth-child(3) {
          color: #497AF8;
        }
      }

    }
  }

  .recharts-wrapper .recharts-cartesian-grid-vertical line {
    stroke-opacity: 0;
  }

  .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child {
    stroke-dasharray: 0;
  }

  .recharts-wrapper .recharts-cartesian-grid-horizontal line {
    stroke-dasharray: 3;
  }

  .recharts-cartesian-axis-line {
    stroke: rgba(122, 126, 137, 0.36);
  }
}