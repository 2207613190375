.header {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  display: flex;
}

.certicifate {
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 15px;
  }

  span:first-child {
    margin-right: 15px;
    align-self: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #7a7e89;
  }
}

.titlePage {
  align-self: center;
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #7a7e89;
}

.certicifateId {
  align-self: center;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1a1d21;
  margin-right: 40px;
}

.btnSearch {
  display: flex;
  justify-content: center;
  width:  78px;
  height: 20px;
  background: linear-gradient(115.74deg, #dc01ff 13.06%, #3736ff 99.99%);
  border: 1px solid rgba(217, 111, 219, 0.7);
  border-radius: 12px;
  padding: 10px 20px;
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  .btnText {
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    color: #ffffff;
  }
}

.selectInputWrap {
  margin-top: -15px;
  width: 180px;
  margin-right: 20px;

}

.buttonWrap {
  margin-right: 20px;

  .indicatorButton {
    margin: 12px 10px;
  }

  span {
    color: #1A1D21;
    margin: 12px 10px 12px 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
  }
}

.buttonOpenSelect {
  background: #F4F6FA;
  backdrop-filter: blur(8.03279px);
  border-radius: 10px;
  // width: 76px;
  height: 40px;
  border: none;
  cursor: pointer;
}