.bar-chart {
  .recharts-wrapper .recharts-cartesian-grid-vertical line {
    stroke-opacity: 0;
  }
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child {
    stroke-dasharray: 0;
  }
  .recharts-cartesian-axis-line {
    stroke: rgba(122, 126, 137, 0.36);
  }
}
