.rangeInput {
  position: relative;

  &__min,
  &__max {
    position: absolute;
    top: -.1em;

    font-size: 12px;
    font-weight: 500;
    text-align: center;

    background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &__min {
    left: 0;
  }

  &__max {
    right: 0;
  }

  &__track {
    position: relative;
    // z-index: 0;
    height: 3px;
    width: 100%;
    border-radius: 4px;

    &::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      border: 1px solid rgba(217, 111, 219, 0.5);
      border-radius: 4px;
    }
  }

  &__thumb {
    border-radius: 6px;
    width: 7px;
    height: 7px;

    &:focus-visible {
      box-shadow: 0 0 0 5px rgba(#003BD2, .1) !important;
    }
  }

  &__tooltip {
    position: absolute;
    top: -30px;
    
    span {
      display: block;
      padding: 4px;
      padding-bottom: 3px;
      border-radius: 10px;
  
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 14px;
      color: white;
      font-weight: bold;
      line-height: 1.1;
    }

    svg {
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 100%;
      transform: translateX(-50%) scaleY(-1);
      margin-top: -0.35em;
      width:  20px;
      height: 12px;
    }
  }
}