.tr {
  position: relative;
  display: flex;
  padding: 13.5px 15px;
  border-radius: 10px;
  
  font-size: 12px;
  
  &[class~=selected],
  &:hover {
    background: #F9FAFC;
  }

  &::after {
    content: "";
    position: absolute;
    width: calc(100% - 22px);
    height: 1px;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -100%);
    background: rgba(185, 189, 200, 0.1);
  }

  &:last-of-type::after {
    display: none;
  }
}

.td {
  display: flex;
  align-items: center;
}