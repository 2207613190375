.tooltipBar{
    display: flex;
    flex-direction: column;
    width: 147px;
    background: #5B6270;
    border-radius: 5px;
    padding: 5px 8px;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 9px;

    .tooltipProp{
        font-weight: 500;
    }
}
.flexRow{
    display: flex;
    flex-direction: row;
}
.boxGreen {
    text-align: center;
    padding: 5px;
    border-radius: 3.62834px;
    background: #00C566;
    margin: 5px 5px 5px 0;
}
.boxPink {
    padding: 5px;
    text-align: center;
    border-radius: 3.62834px;
    background: #CC027B;
    margin: 5px 5px 5px 0;
}



.SankeyChartWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.HomeLabel {
    position: absolute;
    left: 28px;
    top: 40px;
    font-family: 'Subjectivity';
    font-style: normal;
    font-weight: 700;
    font-size: 9.19866px;
    line-height: 110%;
    color: #fff;
}

.ConnectWalletLabel {
    position: absolute;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    top: 30px;
    font-family: 'Subjectivity';
    font-style: normal;
    font-weight: 700;
    font-size: 9.19866px;
    line-height: 110%;
    color: #fff;
    width: 80px;
}

.FirstActionLabel {
    position: absolute;
    right: 20px;
    top: 20px;
    font-family: 'Subjectivity';
    font-style: normal;
    font-weight: 700;
    font-size: 9.19866px;
    line-height: 110%;
    color: #fff;
}


// @media screen and (max-width: 499px) {

//     .HomeLabel {
//         left: 28px;
//     }
    
//     .ConnectWalletLabel {
//         left: 134px;
//     }
    
//     .FirstActionLabel {
//         left: 264px;
//     }
// }

// @media screen and (max-width: 400px) {

//     .HomeLabel {
//         left: 28px;
//     }
    
//     .ConnectWalletLabel {
//         left: 114px;
//     }
    
//     .FirstActionLabel {
//         left: 234px;
//     }
// }