
.btnWrapper {
  position: relative;
  transition: transform 0.2s ease-in-out;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.btn {
  white-space: nowrap;
  background: linear-gradient(115.74deg, #dc01ff 13.06%, #3736ff 99.99%);
  padding: 11px 20px;
  border: 1px solid rgba(217, 111, 219, 0.7);
  backdrop-filter: blur(10px);
  // max-width: 163px;
  height: 40px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: box-shadow 0.6s ease-in-out;

  &:hover {
    background: linear-gradient(110.12deg, #dc01ff 16.7%, #3736ff 148.66%);
    box-shadow: 0px 15px 40px rgba(220, 1, 255, 0.4);
  }

  &:disabled,
  &[disabled] {
    white-space: nowrap;
    max-width: 159px;
    padding: 11px 20px;
    border: white;
    // max-width: 163px;
    height: 40px;
    border-radius: 12px;
    color: rgba(255, 255, 255, 0.642);
    font-size: 16px;
    cursor: pointer;
    opacity: 0.7;
    cursor: not-allowed;

    &:hover {
        transform: translateY(0);
    }
    &:hover {
        background: linear-gradient(115.74deg, #dc01ff 13.06%, #3736ff 99.99%);
        box-shadow: none;
    }
  }
  
}

.leftStar {
  transition: all 0.6s ease;
  opacity: 0;
  position: absolute;
  height: 11px;
  width: 11px;
  left: -12px;
  bottom: 5px;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 0L5.84323 4.15116C5.88754 4.68712 6.31288 5.11246 6.84884 5.15677L11 5.5L6.84884 5.84323C6.31288 5.88754 5.88754 6.31288 5.84323 6.84884L5.5 11L5.15677 6.84884C5.11246 6.31288 4.68712 5.88754 4.15116 5.84323L0 5.5L4.15116 5.15677C4.68712 5.11246 5.11246 4.68712 5.15677 4.15116L5.5 0Z' fill='white'/%3E%3C/svg%3E%0A");
}

.topStar {
  transition: all 0.6s ease;
  opacity: 0;
  position: absolute;
  height: 20px;
  width: 20px;
  right: 18px;
  top: -15px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0L10.624 7.54756C10.7046 8.52203 11.478 9.29538 12.4524 9.37595L20 10L12.4524 10.624C11.478 10.7046 10.7046 11.478 10.624 12.4524L10 20L9.37595 12.4524C9.29538 11.478 8.52203 10.7046 7.54756 10.624L0 10L7.54756 9.37595C8.52203 9.29538 9.29538 8.52203 9.37595 7.54756L10 0Z' fill='white'/%3E%3C/svg%3E%0A");
}

.bottomStar {
  transition: all 0.6s ease;
  opacity: 0;
  position: absolute;
  height: 15px;
  width: 15px;
  right: 0;
  bottom: -11px;
  left: 0;
  margin: 0 auto;
  padding-left: 12px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 0L7.96803 5.66067C8.02846 6.39153 8.60848 6.97154 9.33933 7.03197L15 7.5L9.33933 7.96803C8.60847 8.02846 8.02846 8.60848 7.96803 9.33933L7.5 15L7.03197 9.33933C6.97154 8.60847 6.39152 8.02846 5.66067 7.96803L0 7.5L5.66067 7.03197C6.39153 6.97154 6.97154 6.39152 7.03197 5.66067L7.5 0Z' fill='white'/%3E%3C/svg%3E%0A");
}

.btnIcon {
  margin-right: 12px;
  display: flex;
  align-items: center;
}
.btnWrapperHasIcon {
  .btn {
    padding-left: 12px;
  }
  
}
  
.btnWrapper:hover {
    transform: translateY(-1px);
    .leftStar, .topStar, .bottomStar {
      opacity: 1;
    }
}

.btn:active {
  .leftStar, .topStar, .bottomStar {
    opacity: 0;
  }
}


.btnWrapper.btnWrapperSmall {
    .btn {
        padding: 7.5px 10.5px;
        border-radius: 10px;
        height: 30px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 110%;
    }
    .btn:hover {
        background: linear-gradient(110.12deg, #dc01ff 16.7%, #3736ff 148.66%);
        box-shadow: 0px 7.5px 25px rgba(220, 1, 255, 0.4);
    }
}

.btnWrapper.btnWrapperTypeWhite {
  .btn {
    border: 1px solid #B9BDC8;
    background: white;
    color: #7A7E89;
    transition: all 0.2s ease;

    &:hover {
        background: #7A7E89;
        color: #fff;
        box-shadow:none;
    }

    &:disabled,
    &[disabled] {
      background: #d5d5d5;

      &:hover {
        color: #7A7E89;
      }
    }
  }

  .btnIcon {
    svg {
      path {
        fill: #7A7E89;
      }
    }
  }

  &:hover {
    .btnIcon {
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
}