.wrapper {
  display: flex;
  position: relative;
  max-width: 1440px;
  height: 694px;
}
.wrapper::after {
  content: '';
  background: url(/assets/bg-stars.png);
  background-size: contain;
  opacity: 0.5;
  width: 6000px;
  height: 694px;
  top: 0;
  left: -2400px;
  bottom: 0;
  right: 0;
  position: absolute;
  mix-blend-mode: hard-light;
  z-index: -1;
  overflow: hidden;
}
.wrapper::before {
  content: '';
  width: 1200%;
  left: -500%;
  
  position: absolute;
  top: -20px;
  height: 100px;
  
  background: rgb(25,27,31);
  background: linear-gradient(180deg, rgba(25,27,31,1) 0%, rgba(25,27,31,0.801295518207283) 31%, rgba(25,27,31,0) 100%);
}
.bottomGradient {
  content: '';
  width: 1200%;
  left: -500%;
  position: absolute;
  bottom: 0;
  height: 100px;
  background: rgb(25,27,31);
  background: linear-gradient(0deg, rgba(25,27,31,1) 0%, rgba(25,27,31,0.801295518207283) 31%, rgba(25,27,31,0) 100%);
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 123px 0 150px 183px;
  max-width: 993px;
  height: 421px;
  ul {
    list-style-type: none;
  }
}
.topBlock {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 336px;
  span {
    opacity: 1;
    color: white;
  }
}
.wrapperBlocks {
  display: flex;
  flex-direction: row;
}
.title {
  max-width: 759px;
  height: 239px;
  font-weight: 400;
  font-size: 96px;
  line-height: 110%;
  color: #ffffff;
  font-family: 'Subjectivity';

  i {
    font-weight: 300;
  }
}
.subtitle {
  max-width: 500px;
  height: 50px;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}
.bottomBlock {
  display: flex;
  align-items: center;
  
  .list {
    align-items: center;
    padding: 0;
    display: flex;

    li {
      margin-right: 20px;

      
        filter: grayscale(100%);
        transition: all 0.3s ease;
        
        &:hover {
          filter: grayscale(0%);
        }
      

    }
  }
  .textList {
    margin-right: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #b9bdc8;
  }
}
.rightBlockStar {
  display: flex;
  position: relative;
  width: 256px;
  align-items: flex-end;
}
.star {
  display: flex;
  position: relative;
  width: 256px;
  height: 256px;
}
.starWrap {
  display: flex;
  position: relative;
  
  p {
    width: 100px;
    height: 80px;
    color: white;
    position: absolute;
    margin: 0;
    width: 94px;
    height: 30px;
    font-family: 'Subjectivity';
    text-align: center;
  }
}
.starText {
  position: absolute;
  // top: 182px;
  // left: 83px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 60px;
  width: 100px;

}
.arrow {
  margin-top: 25px;
  cursor: pointer;
  align-items: center;
  margin-left: 10px;
  width: 80px;
  height: 40px;
  transition: width 0.2s ease-in-out;
}

.arrow:hover {
  width: 90px;
}

.star {
  animation: 25s linear 0s normal none infinite running rotating;
}

@media screen and (max-width: 1440px) {
  .wrapper {
    overflow: hidden;
  }
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .container {
    padding: 60px 30px 30px 80px;
  }
  .title {
    height: auto;
  }
  .topBlock {
    height: auto;
  }
  .subtitle {
    height: auto;
  }

  .starWrap {
    position: relative;
  }

  .starText {
    position: absolute;
    width: 100px;
    height: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  .wrapper {
    height: auto;
  }
}

@media screen and (max-width: 867px) {
  .wrapper {
    height: 868px;
  }
  .wrapper::after {
    height: 868px;
  }
  .container {
    padding: 100px 0 150px 40px;
  }
  .wrapperBlocks {
    flex-direction: column;
  }
  .title {
    max-width: 668px;
    
    text-align: center;
  }
  .subtitle {
    max-width: 668px;
    height: 50px;
    text-align: center;
    margin-bottom: 20px;
  }
  .topBlock {
    justify-content: space-evenly;
  }
  .bottomBlock {
    margin-top: 60px;
    flex-direction: column;
    justify-content: center;
  }

  .list {
    li {
      align-self: center;
    }
  }
  .textList {
    align-self: center;
  }
  .rightBlockStar {
    display: flex;
    width: 100% !important;
    height: 256px;
    justify-content: center;
  }
  .starWrap {
    position: relative;
    justify-content: center;
  }
  .starText {
    // top: 105px;
  }
  .textList {
    margin: 0px !important;
  }
}

@media screen and (max-width: 618px) {
  .title {
    font-size: 70px;
  }
}

@media screen and (max-width: 499px) {
  .wrapper {
    height: auto;
    min-height: 700px;
    margin-top: 20px;
    
  }
  .container {
    width: 100%;
    padding: 60px 40px 150px 40px;
  }
  .topBlock {
    justify-content: normal;
    // height: 205px;
    height: auto;
  }
  .title {
    font-size: 32px;
    line-height: 110%;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  .subtitle {
    width: 100%;
    height: 88px;
  }
  .wrapper::before {
    height: 80px;
    background: rgb(25,27,31);
    background: linear-gradient(180deg, rgba(25,27,31,1) 0%, rgba(25,27,31,0.8769257703081232) 23%, rgba(25,27,31,0.801295518207283) 46%, rgba(25,27,31,0) 100%);
  }
  .bottomGradient {
    height: 80px;
    background: linear-gradient(0deg, rgba(25,27,31,1) 0%, rgba(25,27,31,0.8769257703081232) 23%, rgba(25,27,31,0.801295518207283) 46%, rgba(25,27,31,0) 100%);
  }
  .rightBlockStar {
    margin-top: 35px;
  }
  .bottomBlock {
    margin-top: 30px;
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
