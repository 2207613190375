.titles {
  margin-left: 30px;
  margin-top: 30px;
  width: 100%;
  height: 47px;
  margin-bottom: 27px;
  h2,
  h3 {
    margin: 0;
    padding: 0;
  }
  h2 {
    font-family: 'Subjectivity';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    margin-bottom: 10px;
  }
  h3 {
    white-space: nowrap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #7a7e89;
  }
}
