.bg {
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: white;
  position: relative;
  overflow: auto;
}

.content {
  box-sizing: border-box;
  padding-top: 30px;
  position: absolute;
  height: 100%;
  width: calc(100% - 260px);
  left: 260px;
}

.wrapMain {
  box-sizing: border-box;
  min-height: 100%;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
}

.tableTitle {
  margin: 0;
  margin-top:    40px;
  margin-bottom: 30px;

  font-family: 'Subjectivity';
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;
  color: #1A1D21;
}

.addButton {
  margin-right: auto;
  background: transparent;
  border: none;
  padding: 9.5px 15px;
  width: 150px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
  border: 1px solid rgba(220, 1, 255, 0.2);
  border-radius: 10px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  cursor: pointer;

  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
  }

  img,
  svg {
    margin-right: 13px;
  }
}

.pageInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  span {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #B9BDC8;
  }
}

.notificationsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 20px;

  .notificationsTile {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    min-width:  353px;

    padding: 20px;
    border-radius: 15px;
    background: #F4F6FA;

    .logo {
      width:  40px;
      height: 40px;

      img,
      svg {
        width: 100%;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 20px;
      gap: 7px;

      color: #1A1D21;

      .name {
        display: flex;
        align-items: baseline;

        font-family: 'Subjectivity';
        font-weight: 500;
        font-size: 16px;
        line-height: 110%;

        svg,
        img {
          flex-shrink: 0;

          width:  8px;
          height: 8px;

          margin-top:  -2px;
          margin-left: 10px;
        }
      }

      .description {
        max-width: 253px;

        font-family: 'Inter';
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
      }

      .extraInfo {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 130%;
        color: #7A7E89;
      }
    }
  }
}

.popupContainer {
  backdrop-filter: blur(5px);
  width:  100vw;
  height: 100vh;

  position: absolute;
  z-index: 8888;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}