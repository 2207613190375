// .slider {
//   display: flex;
//   justify-content: center;
//   max-width: 1900px;
// }
// .carousel {
//   max-width: 1900px;
// }
// .innerCarousel {
//   display: flex;
// }

// @media screen and (max-width: 1440px) {
//   .slider {
//     overflow: hidden;
//   }
// } ;


.SliderWrapper {
  width: 100%;
  padding-left: 80px;
  
  // box-sizing: border-box;
}
.SwiperSlide {
  max-width: 420px;
}


@media screen and (min-width: 769px) and (max-width: 1440px) {
  .SliderWrapper {
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    
  }
}

@media screen and (max-width: 768px) {
  .SliderWrapper {
    padding-left: 20px;
    // padding-right: 20px;
    box-sizing: border-box;
  }

  .SwiperSlide{
    max-width: 420px;
    // margin: 0 !important;
  }
}

@media screen and (max-width: 499px) {
  .SliderWrapper {
  padding-left: 0px;
  }
}
