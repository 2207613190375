.forgotPasswordPage {
	display: flex;
	justify-content: center;
	background-color: #fff;
	height: 100vh;

	.wrapper {
		padding-top: 43px;
		min-width:  347px;
		width: 			347px;

		display: flex;
		align-items: center;
		flex-direction: column;

		padding: auto;

		.logo {
			display: flex;
		}

		h3 {
			margin-top: 38vh;

			font-size: 18px;
			font-family: 'Inter';
			font-weight: 400;
			line-height: 140%;
			text-align: center;
			color: #7A7E89;
		}

		.content {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			margin-top: 222.5px;

			h1,
			h2 {
				margin: 0;
				padding: 0;
				font-style: normal;
				text-align: center;
			}

			h1 {
				font-size: 40px;
				font-family: 'Subjectivity';
				font-weight: 500;
				line-height: 110%;
				margin-bottom: 20px;

				color: #1A1D21;
			}

			h2 {
				margin-bottom: 40px;
				font-size: 18px;
				font-family: 'Inter';
				font-weight: 400;
				line-height: 140%;
				text-align: center;
	
				color: #7A7E89;
			}

		}
	}
}

.backButton {
	cursor: pointer;
	margin-top: 15px;
	padding: 7px 11px;
	border-radius: 10px;

	font-family: 'Inter';
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	background-color: transparent;
	border: none;

	text-align: center;
	color: #7A7E89;
	transition: background-color 0.2s ease-in-out;

	&:hover {
		background-color: #f6f6f6;
	}
}

.error {
	margin-top: -15px;
	margin-bottom: 15px;
	padding: 0;

	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	color: #f2114d;
	text-align: center;
}