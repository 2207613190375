.infoBlock {
  display: flex;
  width: 413px;
  height: 499px;
  border: 1px solid rgba(242, 242, 242, 0.15);
  border-radius: 15px;
  background-color: #191b1f;
  transition: transform 0.3s ease-in-out, background-color 0.2s ease-in-out;
  margin-right: 20px;
  overflow: hidden;
}

.img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 354px;
  height: 300px;

    div {
      svg {
        width: auto !important;
        height: auto !important;
      }
    }
  
}


.description {
  display: flex;
  flex-direction: column;
  width: 354px;
  height: 124px;
  span:first-child {
    font-family: 'Subjectivity';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 120%;
    color: white;
    font-weight: 500;
    font-size: 30px;
  }
  span:last-child {
    margin-top: 20px;
    color: white;
    font-weight: 400;
    font-size: 16px;
  }
}
.blockWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;

  
}
.infoBlock:hover {
  transform: translateY(-10px);
  background-color: lighten(#191b1f, 2%);
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .infoBlock {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
    height: auto;
    // padding-bottom: 50px;
  }
  .description {
    width: 100%;
    height: auto;
  }
  
  .blockWrap {
    padding: 20px;
    box-sizing: border-box;

    img {
      max-width: 320px;
      width: 90%;
      // height: auto;
      // min-height: 300px;
      padding: 40px;
    }

    div {
      max-width: 320px;
      width: 100%;

      
      
      // height: auto;
      // min-height: 300px;
    }
   
  }
}

@media screen and (min-width: 700px) and (max-width: 768px) {
  .infoBlock {
    width: 688px;
    height: 287px;
    margin-bottom: 20px;
  }
  .img {
    width: 50%;
    height: 50%;
  }
  .description {
    width: 50%;
    height: 50%;
  }
  .blockWrap {
    padding: 0;
    flex-direction: row;
    display: flex;
    align-items: center;
  }
  .infoBlock:hover {
    transform: translateY(0);
  }
}

@media screen and (max-width: 499px) {
  .infoBlock {
    // width: 295px;
    width: 90%;
    height: auto;
    min-height: 500px;
    margin: 11px 20px;
  }
  .description {
    width: 100%;
    height: auto;
    margin-left: 10px;
  }
  .blockWrap {
    width: 100%;
    padding: 0px 30px 50px 30px;
    box-sizing: border-box;
  }
  .img {
    transform: scale(0.9);
    margin-left: -20px;
  }
}
