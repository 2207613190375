.scatter {
  margin-top: -.3em;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  
  > * {
    margin-bottom: 8em;
    width:  49%   !important;
    height: 380px !important;
  }
}

.chartWrapper {
  position: relative;
}

.chartTitle {
  margin: 0;
  margin-bottom: 2.35em;

  font-family: 'Subjectivity';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #1A1D21;
}