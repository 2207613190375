.assetsBlock {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  max-width: 50px;
  height: 21px;
  border: 1px solid rgba(223, 227, 236, 0.3);
  border-radius: 6px;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    color: #1a1d21;
  }
}