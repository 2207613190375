.colorPicker {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #DFE3EC;
  border-radius: 10px;
  width: 53px;

  .circle {
    border: none;
    padding: 0;
    display: inline-flex;
    width:  1em;
    height: 1em;
    border-radius: 50%;
    
    font-size: 15px;

    cursor: pointer;
  }

  .selectedColorContainer {
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;

    cursor: pointer;

    > * {
      box-sizing: border-box;
    }

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 5px 7px;
      width: 100%;
      border: none;
      background-color: unset;

      cursor: pointer;

      img,
      svg {
        margin-top:   2px;
        margin-right: 2px;
      }
    }
  }

  .colorSelectContainer {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    z-index: 3;
    top: 100%;
    left: 0;

    margin-top: 5px;
    padding: 7px;
    width: 172px;

    background-color: white;
    border: 1px solid #DFE3EC;
    border-radius: 10px;
    gap: 7px;
  }
}