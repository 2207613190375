.btn {
  cursor: pointer;
  max-width: 84px;
  height: 25px;
  padding: 7px 11px;
  text-align: center;
  background: #1a1d21;
  border: 1px solid rgba(122, 126, 137, 0.4);
  backdrop-filter: blur(7.0765px);
  border-radius: 7.0765px;
  color: white;
  font-weight: 500;
  font-size: 10px;
  line-height: 110%;
}
.btnActive {
  cursor: pointer;
  max-width: 84px;
  height: 25px;
  padding: 7px 11px;
  text-align: center;
  background: #1a1d21;
  border: 1px solid #dc01ff;
  backdrop-filter: blur(7.0765px);
  border-radius: 7.0765px;
  color: white;
  font-weight: 500;
  font-size: 10px;
  line-height: 110%;
}
.btn:hover {
  border: 1px solid #dc01ff;
}
