.wrapper {
  display: flex;
  max-width: 1440px;
  height: 335px;
  flex-direction: row;
  justify-content: space-between;
  h1,
  h2 {
    margin: 0;
  }
  .investorsLeft, .investorsRight {
    a {
      display: flex;
      list-style-type: none;
      color: white;
      border: 1px solid rgba(242, 242, 242, 0.15);
      border-radius: 10px;
      text-decoration: none;
    }
    a {
      margin: 5px;
      display: flex;
      padding: 5px 8px;
      flex-direction: row;
      max-width: 150px;
      align-self: center;
      margin-right: 10px;
    }
  }
  
}
.investorsLeft {
  display: flex;
  align-self: center;
  width: 33%;
  height: 73px;
  flex-direction: row;
  flex-wrap: wrap;
  white-space: nowrap;
  .topInv {
    margin-left: 140px !important;
  }
  .mediumInv {
    margin-left: 120px !important;
  }
  .bottomInv {
    margin-left: 280px !important;
  }
}
.investorsRight {
  display: flex;
  align-self: center;
  width: 33%;
  height: 73px;
  flex-direction: row;
  flex-wrap: wrap;
  white-space: nowrap;
  .topInv {
    margin-left: 60px !important;
  }
  .mediumInv {
    margin-left: 120px !important;
  }
  .bottomInv {
    margin-left: 100px !important;
  }
}

.centerContent {
  width: 33%;
}

.title {
  margin-top: 65px !important;
  text-align: center;
  color: white;
  font-family: 'Subjectivity';
  font-weight: 500;
  font-size: 40px;
  line-height: 110%;
}
.subtitle {
  margin-top: 20px !important;
  text-align: center;
  color: #b9bdc8;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}
.expertsImg {
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 1280px) {
  .wrapper {
    flex-direction: column;
    align-self: center;
  }
  .investorsLeft {
    justify-content: center;
    align-self: center;
    width: 485px;
  }
  .centerContent {
    justify-content: center;
    align-self: center;
    margin-bottom: 40px;
  }
  .title {
    white-space: nowrap;
  }
  .subtitle {
    width: 497px;
    height: 50px;
    white-space: nowrap;
    margin-left: -120px !important;
  }
  .investorsRight {
    justify-content: center;
    align-self: center;
    width: 481px;
  }
  .imgSouth {
    margin-right: 30px;
  }
  .investorsLeft {
    .topInv {
      margin-left: 0px !important;
    }
    .mediumInv {
      margin-left: 0px !important;
    }
    .bottomInv {
      margin-left: 0px !important;
    }
  }
  .investorsRight {
    .topInv {
      margin-left: 0px !important;
    }
    .mediumInv {
      margin-left: 0px !important;
    }
    .bottomInv {
      margin-left: 0px !important;
    }
  }
}

@media screen and (max-width: 665px) {
  .investorsLeft {
    width: 600px;
  }

  .investorsRight {
    width: 600px;
  }
}
@media screen and (max-width: 596px) {
  .investorsLeft {
    width: 570px;
  }

  .investorsRight {
    width: 550px;
  }
}

@media screen and (max-width: 552px) {
  .investorsLeft {
    width: 490px;
  }

  .investorsRight {
    width: 520px;
  }
}
// @media screen and (max-width: 488px) {
//   .investorsLeft {
//     width: 490px;
//     margin-bottom: 40px;
//   }
//   .investorsRight {
//     width: 520px;
//   }
// }
// @media screen and (max-width: 482px) {
//   .investorsLeft {
//     width: 450px;
//     margin-bottom: 40px;
//   }
//   .investorsRight {
//     width: 450px;
//   }
// }

// @media screen and (max-width: 376px) {
//   .investorsLeft {
//     width: 315px;
//     span {
//       font-size: 12px;
//     }
//   }

//   .title {
//     white-space: nowrap;
//     font-weight: 500;
//     font-size: 28px;
//     line-height: 110%;
//   }
//   .subtitle {
//     width: 295px;
//     height: 60px;
//     font-weight: 400;
//     font-size: 16px;
//     white-space: normal;

//     line-height: 140%;
//     margin-left: -85px !important;
//   }
//   .expertsImg {
//     width: 245px;
//     height: 40px;
//     margin-top: 45px !important;
//     margin-left: -65px !important;
//   }

//   .investorsRight {
//     width: 421px;
//     span {
//       font-size: 12px;
//     }
//   }
// }


/* Дополнительные стили */

@media screen and (max-width: 499px) { 
  .wrapper {
    height: auto;
  }
  .centerContent {
    width: 100%;
    margin-bottom: 30px;
  }
  .subtitle {
    height: auto;
    margin-left: 0px !important;
    width: 100%;
    padding: 0px 40px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    white-space: unset;

    br {
      display: none;
    }
  }
  .expertsImg {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    img {
      width: 30%;
      height: auto;
    }
  }

  .investorsLeft, .investorsRight {
    height: auto;
    width: 90%;
    margin-bottom: 0;
  }
  .investorsLeft a, .investorsRight a {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }
  .title {
    margin-top: 30px !important;
  }
}