.account {
  background: none;
  border: 1px solid rgba(223, 227, 236, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 12.5px 15px;

  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  color: #1A1D21;

  cursor: pointer;
}