.wrapper {
  max-width: 1440px;
  // height: 350px;
  justify-content: center;
  margin-top: 80px;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.title {
  display: flex;
  max-width: 1080px;
  // max-height: 212px;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: 'Subjectivity';
  text-align: center;
  

  span:nth-child(1) {
    color: white;
    font-weight: 500;
    font-size: 96px;
    line-height: 110%;
  }
  span:nth-child(2) {
    color: white;
    font-weight: 500;
    font-size: 96px;
  }
}
// .title {
//   display: flex;
//   flex-direction: column;
//   margin-top: 50px;
//   text-align: center;

//   span:nth-child(1) {
//     color: white;
//     font-weight: 500;
//     font-size: 96px;
//   }
//   span:nth-child(2) {
//     color: white;
//     font-weight: 500;
//     font-size: 96px;
//   }
// }

.titleSecond {
  display: flex;
  width: 650px;
  // height: 56px;
  flex-direction: column;
  // margin-top: 20px;
  text-align: center;

  span:nth-child(1) {
    color: white;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
  }
  span:nth-child(2) {
    color: white;
    font-weight: 400;
    line-height: 140%;
    font-size: 20px;
  }
}

.smallStartImage {
  margin-top: 44px;
  margin-left: 128px;
  z-index: -1;
  position: absolute;
}

.littleStar {
  margin-top: 122px;
  margin-left: -41px;
  z-index: -1;
  position: absolute;
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .wrapper {
    max-width: 100%;
    width: 100%;
  }
  .title {
    max-width: 100%;
    width: 90%;

    
  }
  .title span:nth-child(1) {
      color: white;
      font-weight: 500;
      font-size: 84px;
  }
}


@media screen and (max-width: 768px) {
  .title {
    span {
      font-size: 60px !important;
    }
  }
  .littleStar {
    margin-top: 116px;
    margin-left: -29px;
  }
  .smallStartImage {
    margin-top: 50px;
  }
}

@media screen and (max-width: 868px) {
  .title {
    margin-top: 80px;
    span {
      font-size: 85px !important;
    }
  }
}

@media screen and (max-width: 633px) {
  .title {
    margin-top: 80px;
    span {
      font-size: 60px !important;
    }
  }
}

@media screen and (max-width: 499px) {
  .title {
    width: 80%;
    margin-top: 80px;
    
    span {
      font-size: 32px !important;
    }
  }
  .littleStar {
    width: 40px;
    height: 40px;
    margin-top: 91px;
    margin-left: -14px;
  }
  .smallStartImage {
    width: 55px;
    margin-top: 47px;
    margin-left: 53px;
  }
  .titleSecond {
    // width: 295px;
    height: 96px;
    span:nth-child(1) {
      font-weight: 400;
      font-size: 17px;
    }
    span:nth-child(2) {
      font-weight: 400;
      font-size: 17px;
    }
  }

  .title span {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 32px !important;
    line-height: 110% !important;
    text-align: center !important;
  }
  .titleSecond span {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 17px !important;
    line-height: 140% !important;
    text-align: center !important;
    padding: 0px 40px;
  }
  .wrapper {
    height: auto;
  }
  .smallStartImage {
    width: 55px;
    height: auto;
    right: 115px;
  }
  .littleStar {
    width: 40px;
    height: auto;
    margin-top: 94px;
    margin-left: -12px;
  }
}
