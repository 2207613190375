.custom-tooltip {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);

  position: relative;
  top: -135px;
  left: -18px;

  &--bar {
    left: -13px;
    top: -105px;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 5px 11px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #dfe3ec;
  border-radius: 10px;
  
  p {
    font-size: 12px;
    margin: 5px 10px;
    color: #497AF8;
    span {
      color: #7A7E89;
    }
  }
}