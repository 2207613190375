.button {
  display: inline-flex;
  align-items: center;

  background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
  background-clip: text;
  color: transparent;
  border: none;
  padding: 9.5px 5px;

  transition: all 0.3s ease;

  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  cursor: pointer;

  &:hover,
  &:focus {
    background-position: -30px;
  }

  &[disabled] {
    color: #B9BDC8;

    cursor: not-allowed;
  }

  img,
  svg {
    margin-left: 10px;
  }
}

.loader {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}