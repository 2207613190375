.appWrapperOwner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;
}

.imgForm {

  margin-top: 40px;
  width: 112px;
  height: 25px;
}

.imgFormWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imgFormWrapperW {
  display: flex;
  flex-direction: row;
  justify-content: center;
}