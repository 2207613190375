.FilterDropdownFormWrapper {
  position: relative;
}

.FilterDropdownFormHeader {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid rgba(185, 189, 200, 0.3);
  padding: 18px;
}

.FilterDropdownFormFilterSection {
  margin-top: 12px;
  padding: 0px 18px;

  &:first-child {
    margin-top: 0px;
    padding-top: 12px;
  }
}

.FilterDropdownFormFilterField {
  padding: 0 11px 0 22px;
}

.FilterDropdownFormFilterFieldSelected {
  position: relative;
  background: #f8f8f9;
  margin-top: 0px;
  padding: 12px 18px;
}

.walletsLabel {
  display: inline-flex;
  align-items: center;
  margin: 0px 18px;
  margin-top: 23px;
  margin-bottom: 18px;
  padding: 5.5px 5px;
  gap: 2px;
  background: #F4F6FA;
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
  color: #1A1D21;

  &Button {
    display: inline-flex;
    background: none;
    border: none;
    padding: 0;

    cursor: pointer;
  }
}