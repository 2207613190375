.appWrapperOwner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;
}
.appWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bgStar {
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 900px;
  position: absolute;

  opacity: 0.5;
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(25,27,31,1)), to(rgba(25,27,31,0)));
  mask-image: linear-gradient(to bottom, rgba(25,27,31,1), rgba(25,27,31,0));
}


.bgStarWrapper {
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  position: absolute;
  opacity: 0.5;
  background-image: url(/assets/bg-stars.png);
  background-size: cover;
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(25,27,31,1)), to(rgba(25,27,31,0)));
  mask-image: linear-gradient(to bottom, rgba(25,27,31,1), rgba(25,27,31,0));
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .appWrapperOwner {
    max-width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .bgStar {
    width: 100%;
    height: auto;
  }
  .bgStarWrapper {
    width: 100%;
    height: 600px;
  }
}

@media screen and (max-width: 499px) {

  .bgStar {
    width: 100%;
    height: 380px;
    opacity: 1;
  }
  .bgStarWrapper {
    width: 100%;
    height: 500px;
  }


}