.scatter-wrapper {
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child,
  .recharts-wrapper .recharts-cartesian-grid-vertical line:last-child {
    stroke-opacity: 0;
  }
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:nth-child(2),
  line:nth-child(4),
  line:nth-child(5),
  .recharts-wrapper .recharts-cartesian-grid-vertical line:nth-child(2) {
    stroke-opacity: 0;
  }
  .recharts-wrapper .recharts-cartesian-grid-vertical line:nth-child(3),
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:nth-child(3) {
    stroke-dasharray: 4;
  }

  .recharts-surface {
    overflow: initial;
  }
  .recharts-cartesian-axis-line {
    stroke: rgba(122, 126, 137, 0.36);
  }
  .recharts-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 110%;
  }
}