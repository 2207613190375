.header {
  display: flex;
  max-width: 1440px;
  height: 80px;
  justify-content: space-between;
  position: fixed;
  width: 100%;
 
  transition: all 0.3s ease;
  z-index: 100;
  top:0px;
}
.header.isAnnoncementShow {
  top: 40px;
}
.headerScrolled {
  background-color: #1A1D21;
  border-radius: 0px 0px 30px 30px;
  
}
.header.isAnnoncementShow.headerScrolled {
  top: 0px;
}


.logo {
  cursor: pointer;
  display: flex;
  margin-left: 40px;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
  cursor: pointer;
  ul {
    border-radius: 12px;
    padding: 0;
    list-style-type: none;
    li:first-child {
      margin-left: 20px;
    }
    li:last-child {
      margin-right: 20px;
    }
    li:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.navlist {
  border: 1px solid rgba(242, 242, 242, 0.15);
  height: 38px;
  width: 327px;
  display: flex;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  transition: color 0.3s ease-in-out;
  white-space: nowrap;
  li {
    color: white;
    transition: color 0.2s ease-in-out;
  }
  li:hover {
    color: #dc01ff;
  }
}

.navlistIconItem {
  width: 25px;
  height: 25px;
  margin-top: 2px;
  img {
    filter: brightness(0) invert(1);
    width: 20px;
    height: 20px;
    transition: filter 0.3s ease-in;

    &:hover {
      filter: brightness(1) invert(0);
    }
  }
}

.btn {
  margin-right: 40px;
  max-width: 159px;
  margin-right: 38px;
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .header {
    max-width: 100%;
  }
}

@media only screen and (max-width: 769px) {
  .navlist {
    border: none;
    justify-content: flex-end;
    width: auto;
  }
  .navlist li {
    display: none;
  }
  .navlist li.navlistIconItem {
    display: block;
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 499px) {
  .header {
    height: 70px;
    justify-content: space-between;
    align-items: center;
    max-width: 460px;
  }
  .logo {
    img {
      width: 91px;
      height: 20px;
    }
  }
  .navlist {
    margin-left: 50px !important;
    border: none;
    width: 30px;
    margin: 0;
    li {
      display: none;
    }
    li:last-child {
      display: flex;
    }
  }
  .navlist li.navlistIconItem {
    display: block;
    margin-right: 15px !important;
  }
  .btn {
    button {
      max-height: 30px;
      font-size: 13px;
      padding: 4px 10px;
    }
  }
  .bImage {
    img {
      top: 97px !important;
      left: 304px;
    }
  }
  .headerScrolled {
    border-radius: 0px 0px 20px 20px;
  }
}

a {
  text-decoration: inherit;
    color: inherit;
}

@media screen and (max-width: 460px) {
  .header {
    max-width:100%;
  }
}

// @media screen and (max-width: 400px) {
//   .header {
//     max-width: 375px;
//   }
// }

// @media screen and (max-width: 375px) {
//   .header {
//     max-width: 350px;
//   }
// }

