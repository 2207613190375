.group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #F4F6FA;
  border-radius: 6px;
}

.groupTitle {
  background: #F4F6FA;
  align-self: flex-start;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 13.5px;
  border-radius: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #1A1D21;

  // У свернутой группы появляется отступ
  &[class~=collapsed] {
    margin-bottom: 0;
  }

  // Открытая группа с фоном перестает иметь скругления снизу
  &[class~=with-bg]:not([class~=collapsed]) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .contextMenuButton {
    display: flex;
    margin-left: auto;
    margin-right: 5px;
  }
}

.collapseButton {
  margin-right: 6px;

  box-sizing: border-box;
  background: none;
  padding: 0;
  border: 0;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;

  cursor: pointer;

  &[class~=collapsed] {
    img,
    svg {
      transform: rotate(-90deg);
    }
  }
}
.groupName {
  font-size: 14px;
}
.number {
  margin-left:  7px;
  margin-right: 3ch;

  font-family: 'Inter';
  color: #B9BDC8;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.switch {
  margin-left: auto;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 12px;
  padding-left: 24px;
  padding-right: 0;
}