.dateInputWrapper {
  display: flex;
  align-items: center;

  input {
    box-sizing: content-box;
    width: 63px;
    padding: 2px 6px;
    margin-left: 11.3px;
    border: none;
    background-color: transparent;

    font-size: 12px;
    font-weight: 400;
    text-align: center;
    line-height: 15px;

    &:focus-visible {
      outline: none;
    }

    &[disabled] {
      color: #7A7E89;
      cursor: not-allowed;
    }
  }

}