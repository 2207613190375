.wrapper {
  display: flex;
  position: relative;
  position: relative;
  padding: 154px 0 0 80px;
  overflow: hidden;
}
.bgStar {
  z-index: -1;
  position: absolute;
  top: 40px;
  left: -45px;
  animation: 25s linear 0s normal none infinite running rotating;
}
@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.info {
  width: 631px;
  height: 303px;
}
.title {
  font-family: 'Subjectivity';
  display: flex;
  text-align: start;
  color: white;
  font-weight: 500;
  font-size: 60px;
  line-height: 110%;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 0 14px;

  i {
    font-weight: 300;
  }
}
.desription {
  margin-top: 30px;
  color: #b9bdc8;
  font-weight: 400;
  font-size: 18px;
}
.scheme {
  display: flex;
  width: 630px;
  height: 480px;
  position: relative;
  bottom: 80px;
}

@media screen  and (min-width: 769px) and (max-width: 1280px) {
  .wrapper {
    margin: 154px 0 0 80px;
    padding: none;
  }
  .wrapper {
    padding: 120px 40px 0px 40px;
    margin-left: 0px;
  }
  .bgStar {
    top: 0px;
    left: -90px;
  }
  .scheme {
    width: calc(100% - 631px);
    height: auto;
  }
}

@media screen and (max-width: 980px) {
  .wrapper {
    margin: 154px 0 0 80px;
    padding: none;
  }
  .wrapper {
    margin-top: 340px;
    flex-direction: column;
    max-width: 100%;
    height: 602;
    margin-left: 40px;
  }
  .scheme {
    margin-top: 20px;
    width: auto;
  }
  
}

@media screen and (max-width: 566px) {
  .scheme {
    transform: translateX(-50px);
  }
}

@media screen and (max-width: 375px) {
  .wrapper {
    max-width: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 40px;
  }
  .scheme {
    transform: translateX(0);
    margin-bottom: 50px;
    
  }
  .info {
    width: 295px;
    height: 248px;
  }

  .title {
    width: 295px;
    height: 140px;
    font-weight: 500;
    font-size: 32px;
  }

  .scheme {
    margin-top: -40px;
    width: 335px;
    height: 254px;
  }
  .desription {
    font-weight: 400;
    font-size: 16px;
  }
  .bgStar {
    width: 174px;
    height: 174px;
    top: -83px;
    left: -68px;
  }
}

@media screen and (max-width: 499px) {
  .wrapper {
    margin-top: 0px;
    height: auto;
    width: 100%;
    // overflow-x: hidden;
    position: relative;
    margin-left: 0px;
    max-width: 100%;
    padding: 0;
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 110%;
  }
  .scheme {
    width: 100%;
    height: auto;
    padding: 0px 40px;
    margin-top: -10px;
    margin-left: 10px;
    box-sizing: border-box;
    overflow: hidden;
    max-height: 350px;
    transform: translateX(0px);
  }

  .info {
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    margin-top: 50px;
  }
  .desription {
    width: 100%;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }

  .bgStar {
    height: auto;
    width: 174px;
    top: 20px;
    left: -25px;
  }
}
