.pageStatus {
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
    
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  svg {
    height: 1em;
    width:  1em;
  }
  
  .processingSvg {
    animation: spin 2s infinite linear;
    user-select: none;
  
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}