.bg {
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: white;
  position: relative;
  overflow: auto;
}

.content {
  box-sizing: border-box;
  padding-top: 30px;
  position: absolute;
  height: 100%;
  width: calc(100% - 260px);
  left: 260px;
}

.wrapMain {
  box-sizing: border-box;
  min-height: 100%;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
}

.contentWrap {
  position: relative;
  margin-top: 40px;
  flex-grow: 1;
  margin-top: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 40px;

  &Spacing {
    display: flex;

    >*+* {
      margin-left: 10px;
    }
  }
}

.tab {
  >*+* {
    margin-left: 30px;
  }

  button {
    background: none;
    border: none;
    padding: 0;

    font-family: 'Subjectivity';
    font-weight: 500;
    font-size: 20px;

    color: #B9BDC8;

    cursor: pointer;

    &[class~=active] {
      position: relative;
      z-index: 0;

      background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;

        margin-top: 8px;
        width: 70px;
        height: 2px;
        background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
        box-shadow: 2px 0px 15px #DE4AF5;
        border-radius: 6px;
      }
    }
  }
}

.addContractButton {
  background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;

  border: 1px solid rgba(220, 1, 255, 0.2);
  border-radius: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;

  cursor: pointer;

  img {
    margin-right: 10px;
  }
}

.pageContent {
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-bottom: 1.5em;
}

.chart {
  position: relative;
  flex-grow: 1;
  border-radius: 15px;
}

.sidebar {
  flex-shrink: 0;
  margin-left: 20px;
  padding: 20px;
  width: 352px;
  background: #FFFFFF;
  border: 1px solid #DFE3EC;
  border-radius: 15px;

  &Section {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 110%;
    color: #1A1D21;

    &Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
    }

    &Title {
      margin: 0;
    }

    &RemoveButton {
      background: none;
      border: none;
      padding: 0;
      margin-left: auto;

      width:  16px;
      height: 16px;

      cursor: pointer;
    }

    &List {
      list-style: none;
      padding: 0;
      margin: 0;

      font-weight: 400;

      &Number {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #6C95FF;
        border-radius: 4px;
        margin-right: 10px;
        width:  16px;
        height: 16px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        color: #FFFFFF;
      }

      li {
        margin-bottom: 5px;
        padding: 10px;
        background: #F4F6FA;
        backdrop-filter: blur(10px);
        border-radius: 8px;
        display: flex;
        align-items: center;

        cursor: move;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.conversionButton {
  background: none;
  padding: 10px;
  border: 1px solid #DFE3EC;
  border-radius: 8px;
  backdrop-filter: blur(10px);

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #1A1D21;
  text-align: left;

  cursor: pointer;

  &[disabled] {
    background: #F4F6FA;
    color: #7a7e89;

    cursor: not-allowed;
  }

  &[class~=active],
  &:hover,
  &:focus {
    background: #F4F6FA;
  }
}

.switch {
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 7px;
    gap: 10px;
  
    background: none;
    border: none;
    border-radius: 8px;
    color: #7A7E89;

    cursor: pointer;

    svg {
      fill: #B9BDC8;
    }
    
    &[class~=active] {
      background: #F4F6FA;
      color: #1A1D21;

      svg {
        fill: #003BD2;
      }
    }
  }
}