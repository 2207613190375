.uiExamplesWrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: row;
    padding: 24px;
    gap: 24px;
    background-color: #fff;

    code {
        display: inline-block;
        padding: 0.2em 0.5em 0.2em;

        background-color: #eee;
        border-radius: .25em;

        font-size: .85em;
        font-family: inherit;
        font-style: normal;
        font-weight: 700;
        line-height: 1;
        white-space: nowrap;
        color: #333;
    }
}

.circleProgress {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        padding: 0;
        margin:  0;
    }
}

.uiExamplesColumn {
    display: flex;
    min-height: auto;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    min-width: 350px;
}

.uiExamplesTitle {
    font-family: 'Subjectivity';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 110%;
    /* identical to box height, or 44px */
}

.uiExamplesSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.btnSearch {
    display: flex;
    justify-content: center;
    height: 20px;
    background: linear-gradient(115.74deg, #dc01ff 13.06%, #3736ff 99.99%);
    border: 1px solid rgba(217, 111, 219, 0.7);
    border-radius: 12px;
    padding: 10px 20px;
    cursor: pointer;

    img {
        margin-right: 10px;
    }

    .btnText {
        font-weight: 500;
        font-size: 16px;
        line-height: 110%;
        text-align: center;
        color: #ffffff;
    }
}