.bg {
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: white;
  position: relative;
  overflow: auto;
}

.content {
  box-sizing: border-box;
  padding-top: 30px;
  position: absolute;
  height: 100%;
  width: calc(100% - 260px);
  left: 260px;
}

.wrapMain {
  box-sizing: border-box;
  min-height: 100%;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0;
  margin-top:    40px;
  margin-bottom: 30px;

  font-family: 'Subjectivity';
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;
  color: #1A1D21;
}

.pageInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  span {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #B9BDC8;
  }
}

.wrapper {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.tile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #F4F6FA;
  max-width: 353px;
  width: 100%;
  padding: 20px;
  border-radius: 15px;

  &Header {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
  }

  &ImgWrapper {
    flex-shrink: 0;
    margin-right: 14px;
    display: flex;
    align-items: flex-start;
    width:  44.44px;
    height: 44.44px;
    border-radius: 10px;

    img,
    svg {
      max-width: 100%;
    }
  }

  &Title {
    margin: 0;

    font-family: 'Subjectivity';
    font-size: 20px;
    color: #1A1D21;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  &Subtitle {
    font-family: 'Inter';
    font-size: 12px;
    color: #7A7E89;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
  }

  &Content {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > * {
      width: 28%;
    }

    &Subtitle {
      display: block;
      margin-top: 6px;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #7A7E89;
    }
  }

  &Members {
    text-align: center;

    &Title {
      font-family: 'Subjectivity';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      color: #1A1D21;
    }
  }

  &Activity {
    text-align: center;
    &Icon {
      display: flex;
      max-height: 24px;
    }
  }

  &Relevance {
    text-align: center;
    &Icon {
      display: flex;
      max-height: 24px;
    }
  }

  &Button {
    box-sizing: border-box;
    align-self: flex-end;

    background:
      linear-gradient(#f4f6fa, #f4f6fa) padding-box,
      linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%) border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    height: 30px;
    border: 1.5px solid transparent;
    border-radius: 10px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13.3px;
    line-height: 110%;
    text-align: center;

    cursor: pointer;

    span {
      background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}