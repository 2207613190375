.groupTitle {
  background: #F9FAFC;
  display: flex;
  align-items: center;
  padding: 13.5px 15px;
  border-radius: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #1A1D21;

  // У свернутой группы появляется отступ
  &[class~=collapsed] {
    margin-bottom: 8px;
  }

  // Открытая группа с фоном перестает иметь скругления снизу
  &[class~=with-bg]:not([class~=collapsed]) {
    border-bottom-left-radius:  0;
    border-bottom-right-radius: 0;
  }

  .contextMenuButton {
    display: flex;
    margin-left: auto;
    margin-right: 5px;
  }
}

.name {
  &[class~=active] {
    background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.collapseButton {
  margin-right: 2.25em;

  box-sizing: border-box;
  background: none;
  padding: 0;
  border: 0;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width:  10px;
  height: 10px;

  cursor: pointer;

  &[class~=collapsed] {
    img,
    svg {
      transform: rotate(-90deg);
    }
  }
}

.number {
  margin-left: 7px;

  font-family: 'Inter';
  color: #B9BDC8;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.contentWrap {
  border-radius: 10px;
  margin-bottom: 8px;
  
  &[class~=with-bg] {
    background: #F9FAFC;
    border-top-left-radius:  0;
    border-top-right-radius: 0;
  }
}