.investorBlock {
  margin: 11px 6px;
  display: flex;
  flex-direction: column;
  width: 295px;
  height: 195px;
  border: 1px solid rgba(242, 242, 242, 0.15);
  border-radius: 15px;
  pointer-events: none;
  transition: transform 0.3s ease-in-out;
}

.top {
  display: flex;
  padding: 20px;
  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    span:first-child {
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 5px;
    }
    span:last-child {
      font-weight: 400;
      width: 300px;
      font-size: 14px;
      color: #7a7e89;
    }
  }
}
.avatar {
  margin-right: 20px;
  img {
    width: 35px;
    height: 35px;
    border-radius: 10px;
  }
}
.comment {
  width: 255px;
  height: 100px;
  font-weight: 400;
  line-height: 140%;
  font-size: 14px;
  color: #b9bdc8;
  padding: 0 20px 20px 20px;
}
