.NotificationsRecipesPopup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 30px;
  max-width: 530px;
  width: 100%;

  background: #FFFFFF;
  border: 1px solid #DFE3EC;
  border-radius: 10px;

  .eventTypesContainer {
    .mainTitle {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #1A1D21;
    }

    .eventTypesContainer {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      width: 511px;
      gap: 5px;

      button {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        background-color: unset;
        border: 1px solid #DFE3EC;
        border-radius: 10px;

        font-family: 'Inter';
        font-size: 14px;
        color: #1A1D21;
        font-weight: 400;

        cursor: pointer;
      }

      .selected {
        border-color: transparent;
        color: #003BD2;
        background-color: #F4F6FA;
      }
    }
  }

  .bottomSection {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    small {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #7A7E89;

      a {
        color: #003BD2;
        margin-left: 5px;
      }
    }

    .buttonsContainer {
      display: flex;
      align-items: center;

      button {
        padding: 11px 20px;
        background-color: transparent;
        border-radius: 10px;
        border: none;
        cursor: pointer;
      }

      .cancel {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: #7A7E89;
      }

      .nextButton {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 16px;
        line-height: 110%;
        background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
        border: 1px solid rgba(217, 111, 219, 0.7);
        color: white;
      }

      button[disabled] {
        opacity: .5;
        user-select: none;
        cursor: not-allowed;
      }
    }
  }
}

.steps {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.step {
  display: flex;
  align-items: center;
  gap: 15px;

  * {
    box-sizing: border-box;
  }

  &Number {
    background-color: #003BD2;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -5px;
    padding: 4px 7px;
    border-radius: 4px;

    font-family: 'Inter';
    font-size: 12px;
    color: white;
    line-height: 1;
    font-weight: 500;
    text-align: center;
  }

  &Name {
    margin-top: -2px;
    background-color: unset;
    border: none;

    font-family: 'Subjectivity';
    color: #1A1D21;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  &Inactive &Number {
    background: #DFE3EC;
    color: white;
  }

  &Inactive &Name {
    color: #DFE3EC;
  }
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &Title {
    font-family: 'Inter';
    font-size: 14px;
    color: #1A1D21;
    font-weight: 400;
    line-height: 140%;
  }

  input,
  textarea {
    padding: 12px;
    border: 1px solid #DFE3EC;
    border-radius: 12px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }

    &:focus-visible {
      outline: none;
    }
  }

  textarea {
    box-sizing: border-box;
    resize: none;
    min-height: 40px;
  }
}

.addConditionButton {
  flex-shrink: 0;

  margin-bottom: 13px;
  background: none;
  border: none;
  padding: 0;
  width:  18px;
  height: 18px;

  cursor: pointer;
}

.conditionsSeparator {
  margin-bottom: 13px;
  width:  14px;
  height: 14px;

  font-family: 'Inter';
  font-size: 14px;
  color: #7A7E89;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  text-align: center;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: #F9FAFC;
  border-radius: 10px;

  font-family: 'Inter';
  font-size: 12px;
  color: #1A1D21;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  &Name {
    margin: 0;
    margin-bottom: 20px;

    font-size: 14px;
    font-weight: 400;
  }

  &Lists {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    dl {
      display: flex;
      flex-direction: column;
      gap: 5px;

      > * {
        display: flex;
        align-items: baseline;
      }
    }

    dt {
      color: #7A7E89;
    }

    dd {
      margin-left: 5px;
      max-width: 200px;
    }
  }
}

.socials {
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  li {
    display: flex;
  }

  &Tile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;
    gap: 15px;
    width: 170px;
    background: none;
    border: 1px solid #DFE3EC;
    border-radius: 10px;

    cursor: pointer;

    &[class~=active] {
      background: #F4F6FA;
      border-color: transparent;
    }

    &IconWrapper {
      width:  25px;
      height: 25px;

      img,
      svg {
        max-width: 100%;
      }
    }

    &Stack {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &Title {
      margin: 0;
      margin-bottom: 5px;

      font-family: 'Inter';
      font-size: 14px;
      color: #1A1D21;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    &Subtitle {
      font-family: 'Inter';
      font-size: 12px;
      color: #7A7E89;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-align: left;
    }
  }
}