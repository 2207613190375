// .marquee {
//     width: 100%;
//     height: 40px;
//     background-color: #ffffff;
    
//     white-space: nowrap;
//     overflow: hidden;
//     box-sizing: border-box;
//     display: flex;
//     align-items: center;
// }
.marquee {
    background-color: #1A1D21;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;

    color: #FFFFFF;

    svg {
        path {
            fill:#FFFFFF; 
        }
    }
}
.marqueeChildrenWrapper {
    display: flex;
    align-items: center;
    background-color: #1A1D21;
    height: 40px;
    width: 100%;
}
// @keyframes marquee {
//     0%   { transform: translate(0, 0); }
//     100% { transform: translate(-100%, 0); }
// }

.m{
    --space: 4vw;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    color: #FFFFFF;
    white-space: nowrap;
    animation: marquee 2.5s infinite linear;
    padding-left: var(--space);
    
    &:after{
      content: attr(data-text);
      padding-left: var(--space);
    }
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0)
    }
    100% {
      transform: translateX(-50%)
    }
  }