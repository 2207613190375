.wrapper {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  margin-top:    27px;
  padding-right: 40px;
}

.chartsWrap {
  display: flex;
  flex-direction: column;
  width: 66%;
  background-color: white;
  height: 890px;
  margin-right:  20px;
}

.blocksInfo {
  display: flex;
  flex-direction: column;
  width: 34%;

  > div {
    border-radius: 20px;
  }
}

.popular {
  background: #edfbf4;
  font-family: 'Subjectivity';
}

.trending {
  margin-top: 20px;
  background: #f6f0fe;
}

.outliers {
  margin-top: 22px;

  background: #f4f6fa;
}

.btnWrap {
  margin: 30px;
  width:  24px;
  height: 24px;

  .btnDownload {
    display: flex;
    justify-content: center;
    border: none;
    background: #ffffff;
    width:  24px;
    height: 24px;

    svg {
      width:  18px;
      height: 18px;
    }
  }
}

.scatterWrap {
  width: 100%;
  height: 505px;
  border: 1px solid rgba(185, 189, 200, 0.3);
  border-radius: 20px;
  background-color: #fff;
}

.scatterChart {
  position: relative;
  width: auto;
  height: 367px;
  margin-left: 30px;
  margin-right: 30px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.barWrap {
  width: 100%;
  height: 365px;
  border: 1px solid rgba(185, 189, 200, 0.3);
  border-radius: 20px;
  margin-top: 20px;
  background-color: #fff;
}

.barChart {
  position: relative;
  width: auto;
  height: 240px;
}

.downloadButton {
  background: none;
  padding: 0;
  border: none;

  cursor: pointer;
}