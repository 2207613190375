.accountWrapper {
  .userInfo {
    display: flex;
    padding-bottom: 40px;
    margin-bottom: 40px;

    border-bottom: 1px solid rgb(185, 189, 200, 0.3);

    img {
      width:  80px;
      height: 80px;
      margin-right: 20px;

      background: #c8c8c8;
      border-radius: 5px;
    }

    .textInfo {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      h1, h2 {
        margin: 0;
        padding: 0;

        font-style: normal;
        font-weight: 400;
      }

      h1 {
        font-size: 24px;
        font-family: 'Subjectivity';
        color: #1A1D21;
        line-height: 120%;
      }

      h2 {
        margin-top: 10px;
        
        font-family: 'Inter';
        font-size: 16px;
        line-height: 140%;
        color: #7A7E89;
      }
    }
  }

  .passwordChangeWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h3,
    h4 {
      margin: 0;
      font-style: normal
    }

    h3 {
      margin-bottom: 30px;

      font-size: 20px;
      font-family: 'Subjectivity';
      font-weight: 500;
      line-height: 120%;
      color: #1A1D21;
    }

    h4 {
      margin-top: 15px;

      font-size: 16px;
      font-family: 'Inter';
      font-weight: 400;
      line-height: 140%;
      color: #7A7E89;
    }

    .isChanged {
      position: relative;
      margin-top: 40px;

      p {
        padding: 0;
        margin:  0;

        font-size: 16px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      svg {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(72%, -55%);
      }
    }


    .inputWrapper {
      position: relative;
      
      > * + * {
        margin-top: 15px;
      }

      input {
        width: 334px;
        height: 40px;
      }

      .inputAndExtraText {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        small {
          margin-left: 12px;

          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          color: #B9BDC8;
        }
      }
    }
  }

}

.button {
  margin-top: 30px;
}

.error {
  position: absolute;
  left:   0;
  top: 100%;
  margin: 0;
  margin-top: 0.35em !important;

  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: #E01E5A;
}