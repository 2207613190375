.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1440px;
  // height: 1270px;
  width: 100%;
  height: auto;
  padding-bottom: 60px;
  h1,
  h2,
  span,
  ul {
    margin: 0;
    padding: 0;
    color: white;
  }
  p {
    margin: 0;
    padding: 0;
    color: #b9bdc8;
  }
}
.topBlock {
  max-width: 890px;
  height: auto;

  h1 {
    font-family: 'Subjectivity';
    font-weight: 500;
    font-size: 60px;
    line-height: 110%;
    margin-bottom: 20px;
  }
  p {
    max-width: 760px;
    // height: 56px;
    height: auto;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 40px;
  }
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
  }
}
.centerBlock {
  max-width: 890px;
  // height: 396px;
  height: auto;
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 20px;
    font-weight: 500;
    font-size: 30px;
    line-height: 120%;
    // margin-bottom: 20px;
  }
  span {
    margin-top: 20px;
    max-width: 760px;
    // min-height: 150px;
    height: auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
  }
  .firstList {
    padding: 20px 0 20px 20px;
    max-width: 760px;
    min-height: 75px;
    height: auto;

    li {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      list-style-type: decimal;
    }
  }
  .secondList {
    max-width: 733px;
    height: 75px;
    margin-left: 40px;
  }
}
.bottomBlock {
  max-width: 890px;
  // height: 211px;
  height: auto;
  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 120%;
    margin-bottom: 20px;
  }
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
  }
  p {
    margin-top: 20px;

    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }
}
.textWrapper {
  padding: 100px 0 0 80px;
}


@media screen and (max-width: 499px) {
  .wrapper {
    // height: 2000px;
  }
  .textWrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
  .topBlock {
    height: auto !important;
    // margin-bottom: 400px;

    // .pBlock {
    //   margin-bottom: 100px;
    // }
  }
  .centerBlock {
    height: auto;
    // margin-bottom: 300px;
  }
}
