.coverageContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.coverageContaier {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.progressBar {
  width: 10ch;
  min-width: 10ch;

  margin-right: 25px;
}

.buttons {
  display: inline-flex;
  align-items: center;
  margin-left: auto;

  > * {
    box-sizing: content-box;

    cursor: pointer;
  }

  .cancel {
    width:  46px;
    height: 17px;
    padding: 5px 8px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    background-color: transparent;
    border: none;
    color: #7A7E89;
  }

  .save {
    width:  33px;
    height: 17px;
    padding: 5px 8px;

    border: 1px solid rgba(220, 1, 255, 0.2);
    border-radius: 10px;
    background: #F9FAFC;
    margin-left: 15px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    &[disabled] {
      cursor: not-allowed;
      opacity: .5;
    }
  }
}

.activeName {
  background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}