.contextMenuWrapper {
  position: relative;
  box-sizing: border-box;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .addData {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 19px;

    border: none;
    border-radius: 5px;
    background: transparent;

    &:hover {
      background-color: #f4f4f5;
    }
  }

  button {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    border: none;
    background-color: transparent;

    cursor: pointer;

    &[disabled] {
      opacity: .5;
      cursor: not-allowed;
    }
  }


  .popup {
    z-index: 10;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 100%;
    width: 200px;
    margin-top: 10px;

    background: #fff;
    border-radius: 12px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        cursor: pointer;

        &:hover {
          background-color: #f4f4f5;
        }

        button {
          width: 100%;
          padding: 10px;

          span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 120%;
          }

          .svgWrapper {
            display: inline-flex;
            justify-content: center;
            align-items: center;

            width:  10px;
            height: 10px;
            margin-right: 17px;
          }
        }
      }

      .active {
        position: relative;

        img {
          width:  7px;
          height: 7px;
        }

        button {
          padding: 10px;
          // padding-bottom: 12px;

          span {
            background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
        }
      }

      li {
        display: inline-flex;
        align-items: center;
        width: 100%;

        img {
          height: 17px;
          width: 17px;
        }

        span {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;

          color: #1A1D21;
        }
      }
    }
  }
}