.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1440px;
  height: 492px;
  overflow: hidden;
}
.wrapContent {
  display: flex;
  justify-content: space-between;
}
.topLine {
  margin-left: 80px;
  display: flex;
  max-width: 1280px;
  border-top: 1px solid rgba(242, 242, 242, 0.15);
}
.bottomLine {
  display: flex;
  margin-left: 80px;
  margin-top: 60px;
  max-width: 1280px;
  border-top: 1px solid rgba(242, 242, 242, 0.15);
}
.logo {
  margin-bottom: 50px;
}
.bImage {
  position: absolute;
  z-index: -1;
  top: 115px;
  left: 410px;
  animation: 25s linear 0s normal none infinite running rotating;
}
.footerContentEmpty {
  position: absolute;
  top: 22px;
  right: 82px;
}
.contentInfo {
  display: flex;
  flex-direction: column;
  span:nth-child(1) {
    font-family: 'Subjectivity';
    color: #ffffff;
    font-weight: 500;
    font-size: 30px;
  }
  span:nth-child(2) {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #b9bdc8;
  }
}
.contentLeft {
  flex-direction: column;
  width: 488px;
  height: 272px;
  padding: 100px 0 0 80px;
}
.arrow {
  margin-top: 30px;

  img {
    width: 80px;
    height: 40px;
    transition: all 0.3s ease;

    &:hover {
      width: 90px;
    }
  }
}
.littleStar {
  z-index: -1;
  position: absolute;
  top: 160px;
  left: -34px;
  width: 50px;
  height: 50px;
}
.innerLeft {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 50%;
  a {
    text-decoration: solid;
    cursor: pointer;
  }

  span {
    font-family: 'Subjectivity';
    color: #ffffff;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 30px;
  }
  a:nth-child(2) {
    font-weight: 400;
    font-size: 20px;
    color: #b9bdc8;
    margin-bottom: 20px;
  }
  a:nth-child(3) {
    font-weight: 400;
    font-size: 20px;
    color: #b9bdc8;
  }
  a:nth-child(4) {
    margin-top: 32px;
    font-weight: 400;
    font-size: 20px;
    color: #b9bdc8;
  }
}
.innerRightInfo {
  width: 224px;
  height: 66px;
  span:nth-child(1) {
    font-family: 'Subjectivity';
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 30px;
  }
  span:nth-child(2) {
    font-weight: 400;
    font-size: 16px;
    color: #b9bdc8;
  }
}
.followBlock {
  display: flex;
  flex-direction: column;
  span:nth-child(1) {
    margin-bottom: 30px;
    font-family: 'Subjectivity';
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
  }
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style-type: none;
    align-items: center;

    li {
      margin-right: 20px;
      

      a {
        cursor: pointer;
        // filter: grayscale(100%);
        transition: all 0.3s ease;
        

        svg {
          transition: all 0.3s ease;
          path {
            transition: all 0.3s ease;
          }
        }

        &:not(:hover) {
          svg {
            path {
              fill: #B9BDC8;
              ;
            }
          }
        }

      }
      
    }
    
  }
}
.innerRight {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 50%;
}
.innerRightInfo {
  display: flex;
  flex-direction: column;
}
.contentRight {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 488px;
  height: 272px;
  padding: 100px 80px 0 0;
}
.underFooter {
  a {
    color: #7a7e89;
    text-decoration: none;
  }
  a:hover {
    color: #abb1be;
    text-decoration: none;
  }
  display: flex;
  padding: 20px 80px 0 80px;
  color: #7a7e89;
  justify-content: space-between;
}

.underFoterRight {
  span:nth-child(1) {
    margin-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    height: 747px;
  }
  .wrapContent {
    flex-direction: column;
  }
  .contentRight {
    padding-left: 80px;
  }

  .innerRight {
    flex-direction: row;
    width: 452px;
    height: 168px;
  }
  .innerLeft {
    span {
      width: 216px;
    }
  }
  .bottomLine {
    margin-top: 0;
  }
  .footerContentEmpty {
    top: 49px;
    right: 121px;
  }
  .contentLeft {
    img {
      top: 122px;
      left: 316px;
    }
  }
  .contentInfo {
    span:nth-child(2) {
      width: 452px;
      height: 66px;
    }
  }
}
@media screen and (max-width: 499px) {
  
  .wrapper {
    overflow: hidden;
    width: 100%;
    height: auto;
    padding: 20px;
    box-sizing: border-box;
    padding-top: 0;
    
    
  }
  .contentLeft {
    padding-left: 40px;
    padding-top: 60px;
    width: 10px;
    height: 40px;
  }
  .contentInfo {
    display: none;
  }
  .contentRight {
    padding-top: 0;
  }
  .innerLeft {
    span:nth-child(1) {
      font-size: 20px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
    }
    span:not(firts-child) {
      font-size: 17px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
   
  }
  .contentRight {
    padding-left: 40px !important;
    flex-direction: column;
    width: 321px;
    height: 412px;
  }
  .innerLeft {
    margin-top: 40px;
  }
  .innerRight {
    flex-direction: column;
  }
  .innerRightInfo {
    opacity: 0;
    // display: none;
    margin-top: 41px;
  }
  .followBlock {
    margin-top: 60px;
  }

  .contentLeft {
    img {
      top: 122px;
      left: 210px;
    }
  }
  .littleStar {
    z-index: -1;
    top: 226px;
    right: 121px;
    z-index: -1;
  }
  .innerRightInfo {
    span:nth-child(1) {
      font-size: 20px !important;
      font-style: normal;
      font-weight: 500;

      line-height: 120%;
    }
    span:nth-child(2) {
      width: 295px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px !important;
      line-height: 140%;
    }
  }
  .followBlock {
    span:nth-child(1) {
      font-size: 20px;
    }
  }
  .topLine {
    width: 100%;
    margin-left: 0;
  }
  .bottomLine {
    margin-top: 120px;

    width: 295px;
    margin-left: 40px;
  }
  .underFooter {
    width: 300px;
    height: 44px;
    span {
      font-size: 12px;
      margin-top: 10px;
    }
    span:nth-child(1) {
      margin-right: 25px;
    }
  }
  .underFooter {
    flex-direction: column-reverse;
  }

  .footerContentEmpty {
    top: 355px; 
    left: 95px;
    z-index: -1;
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
