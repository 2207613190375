.bg {
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: white;
  position: relative;
  overflow: auto;
}
.content {
  box-sizing: border-box;
  padding-top: 30px;
  position: absolute;
  height: 100%;
  width: calc(100% - 260px);
  left: 260px;
}

.wrapMain {
  box-sizing: border-box;
  min-height: 100%;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
}

.contentWrap {
  position: relative;
  margin-top: 40px;
  flex-grow: 1;
  margin-top: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 40px;

  &Spacing {
    display: flex;

    > * + * {
      margin-left: 10px;
    }
  }
}

.tab {
  > * + * {
    margin-left: 30px;
  }

  button {
    background: none;
    border: none;
    padding: 0;

    font-family: 'Subjectivity';
    font-weight: 500;
    font-size: 20px;

    color: #B9BDC8;

    cursor: pointer;

    &[class~=active] {
      position: relative;
      z-index: 0;

      background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;

        margin-top: 8px;
        width: 70px;
        height: 2px;
        background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
        box-shadow: 2px 0px 15px #DE4AF5;
        border-radius: 6px;
      }
    }
  }
}

.addContractButton {
  background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;

  border: 1px solid rgba(220, 1, 255, 0.2);
  border-radius: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;

  cursor: pointer;

  img {
    margin-right: 10px;
  }
}

.pageContent {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.sidebar {
  margin-top: 19px;
  position: relative;
  box-sizing: border-box;

  margin-left: 20px;
  flex-shrink: 0;
  width: 352px;
  border: 1px solid #E8EDF5;
  border-radius: 15px;
  padding: 23px 20px 50px;

  > header {
    margin-bottom: 34px;
  }

  &CloseButton {
    position: absolute;
    right: 10px;
    top:   10px;

    width:  20px;
    height: 20px;
    background: none;
    border: none;
    padding: 0;

    cursor: pointer;

    svg {
      width:  100%;
      height: 100%;
    }
  }

  .jsonTitle {
    margin-right: 10px;
  }

  &__userNumber {
    display: flex;
    align-items: flex-start;
    margin: 0;

    font-family: 'Subjectivity';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #1A1D21;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    h3 {
      margin: 0;
    }

    .copyButton {
      margin-left: 5px;
    }
  }

  &__date {
    margin-top: 17px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
      height: 1em;
      width:  1em;
    }
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #1A1D21;
  }

  section {
    margin-top: 30px;
  }

  &__header {
    margin: 0;
    margin-bottom: 10px;

    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #7A7E89;
  }

  &__details {
    margin: 0;

    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #1A1D21;

    dd {
      display: block;
      overflow: hidden;
      white-space: pre;
      text-overflow: ellipsis;
    }
  }
}

.tableTitle {
  margin-top:    45px;
  margin-bottom: 30px;

  font-family: 'Subjectivity';
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;
  color: #1A1D21;
}

.jsonData {
  box-sizing: border-box;
  display: flex;
  
  pre {
    margin-top: 31px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: pre-line;
  }
}