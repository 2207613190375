.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 1440px;
  flex-wrap: wrap;
  height: 2131px;

  &:before {
    content: "";
    z-index: -1;
    width: calc(200%);
    margin-left: -50%;
    top: 300px;
    height: auto;
    position: absolute;
    min-height: 800px;
    // background-color: red;
    background: url("../../assets/answer-question-bg.png");
    background-size: cover;
    background-position: center;
  }

  &:after {
    content: "";
    z-index: -1;
    width: calc(200%);
    margin-left: -50%;
    bottom: 300px;
    height: auto;
    position: absolute;
    min-height: 800px;
    // background-color: red;
    background: url("../../assets/answer-question-bg-second.png");
    background-size: cover;
    background-position: center;
  }
}
.titles {
  display: flex;
  max-width: 1440px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'Subjectivity';
    margin: 65px 0 0 0;
    color: white;
    font-weight: 500;
    font-size: 60px;
  }
  h2 {
    margin: 30px 0 40px 0;
    color: white;
    font-weight: 400;
    font-size: 18px;
  }
}
.blockList {
  padding: 0 80px 0 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.infinityBlock {
  position: relative;
  padding: 55px 80px 0 80px;
  max-width: 464px;
  height: 201px;
}
.measureBlock {
  padding: 31px 80px 0 80px;
  img {
    width: 458px;
    height: 321px;
  }
}
.mapUserBlock {
  margin-left: 60px;
  // padding: 47px 28px 28px 28px;
  width: 409.69px;
  height: 317px;
  background: #383C44;
  border-radius: 14.9462px;
}
.trackKeyBlock {
  padding: 30px 80px 0 80px;
  img {
    width: 452px;
    height: 288px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .wrapper {
    max-width: 100%;
  }
  .titles {
    max-width: 100%;
  }
  .wrapper {
    flex-wrap: initial;
    height: auto;
  }
  .blockList {
    flex-direction: column;
    padding: 0 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .infinityBlock {
    max-width: 100%;
    padding: 55px 20px 30px 30px;
  }
  
  .mapUserBlock {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    padding: 10px 0px 10px;
    width: 90%;

    img {
      width: 100%;
      height: auto;
    }
  }
  .trackKeyBlock {
    padding: 30px 80px 60px 80px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    flex-wrap: initial;
    height: auto;
  }
  .blockList {
    flex-direction: column;
    padding: 0 20px;
  }

  .titles h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 110%;
    text-align: center;
  }
  .titles h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
  }
  .infinityBlock {
    padding: 55px 80px 0 80px;
  }
  .measureBlock {
    padding: 31px 100px 60px 100px;
  }
}

@media screen and (max-width: 450px) {
  .titles h1 {
    padding: 0px 40px;

    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 110%;
    text-align: center;
  }
  .titles h2 {
    padding: 0px 40px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
  }
  .infinityBlock {
    padding: 0px 20px 20px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .measureBlock {
    padding: 0px 20px 20px;
  }
  .mapUserBlock {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    padding: 10px 0px 10px;
    width: 90%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .trackKeyBlock {
    padding: 0px 20px 30px 20px;
  }
}
