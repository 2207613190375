.copyButton {
  background: none;
  padding: 0;
  border: none;
  border-radius: 50%;
  width:  1.5em;
  height: 1.5em;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    background: rgba(lightgray, .3);
  }
}