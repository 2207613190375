.checkboxLabel {
  width: 100%;
  display: flex;
  margin: 8px 0px;
}

/* The container */
.containerCheckbox {
  position: relative;
  
  display: flex;
  margin-bottom: 12px;
  padding-left: 22px;
  
  font-family: 'Inter';
  font-size: 14px;
  color: #1A1D21;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;

  cursor: pointer;
  user-select: none;
}

.containerCheckboxDisabled {
  cursor: not-allowed;
  opacity: 0.5;

  .checkmark {
    background-color: #f0f0f0;
  }

  input {
    cursor: not-allowed;
  }

}

.containerCheckbox.containerCheckboxDisabled:hover input~.checkmark {
  background-color: #f0f0f0;

}

/* Hide the browser's default checkbox */
.containerCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid rgba(185, 189, 200, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 0.3s ease;

}

/* On mouse-over, add a grey background color */
.containerCheckbox:hover input~.checkmark {
  transition: all 0.3s ease;
  background-color: #f4f4f4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.containerCheckbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerCheckbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerCheckbox .checkmark:after {
  content: "";
  left: 2px;
  top: 4px;
  width: 10px;
  height: 7px;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.08333L3.94737 6L9 1' stroke='url(%23paint0_linear_1270_11501)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1270_11501' x1='3.70476' y1='-0.5' x2='10.1674' y2='4.48436' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23DC01FF'/%3E%3Cstop offset='1' stop-color='%233736FF'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");

}