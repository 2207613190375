.bg {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: white;
  position: relative;
  overflow: auto;
}

.content {
  padding-top: 30px;
  position: absolute;
  width: calc(100% - 260px);
  left: 260px;
}

.wrapMain {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
}

.contentWrap {
  margin-top: 40px;
  padding-right: 41px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 40px;

  &Spacing {
    display: flex;

    >*+* {
      margin-left: 10px;
    }
  }
}

.tab {
  >*+* {
    margin-left: 30px;
  }

  button {
    background: none;
    border: none;
    padding: 0;

    font-family: 'Subjectivity';
    font-weight: 500;
    font-size: 20px;

    color: #B9BDC8;

    cursor: pointer;

    &[class~=active] {
      position: relative;
      z-index: 0;

      background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;

        margin-top: 8px;
        width: 70px;
        height: 2px;
        background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
        box-shadow: 2px 0px 15px #DE4AF5;
        border-radius: 6px;
      }
    }
  }
}

.addContractButton {
  background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;

  border: 1px solid rgba(220, 1, 255, 0.2);
  border-radius: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;

  cursor: pointer;

  img {
    margin-right: 10px;
  }
}