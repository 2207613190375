.ibmBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 509px;
  border: 1px solid rgba(242, 242, 242, 0.15);
  border-radius: 30px;
}

.ibmBlockLeft {
  display: flex;
  flex-direction: column;
  background-color: rgba(5, 48, 173, 0.2);
  width: 480px;
  height: 509px;
  border: 1px solid #0530ad;
  border-radius: 30px;
  margin-top: -1px;
  margin-left: -1px;
  margin-bottom: -1px;
}

.ibmBlockRight {
  display: flex;
  flex-direction: row;
}

.ibmBlockTabletImg {
  display: none;
  margin-top: 30px;
}

.ibmBlockRightLeft {
  width: 400px;
  display: flex;
  flex-direction: column;
}

.ibmBlockRightRight {
  display: flex;
  flex-direction: column;
}

.blockWrap {
  padding: 30px;
}

.img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 354px;
  height: 300px;
}

.ibmImg {
  width: 94px;
  height: 37px;
  margin-top: 43px;
  margin-left: 39px;
}

.description {
  display: flex;
  flex-direction: column;
  span:first-child {
    color: white;
    font-weight: 500;
    font-size: 30px;
  }
  span:last-child {
    margin-top: 20px;
    color: white;
    font-weight: 400;
    font-size: 16px;
  }
}

.title {
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  margin-left: 39px;
  color: white;
  font-weight: 500;
  font-size: 30px;
}

.titleTwo {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 39px;
  color: #b9bdc8;
  font-weight: 400;
  font-size: 18px;
}

.ibmBlockRightTitleWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  margin-left: 39px;
  margin-right: 39px;
}

.ibmBlockRightTitle {
  display: flex;
  flex-direction: column;
  color: white;
  font-weight: 500;
  font-size: 30px;
}

.ibmBlockRightBottom {
  margin-top: 30px;
}

.ibmBlockRightTitleBottom {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  color: #b9bdc8;
  font-weight: 400;
  font-size: 18px;
}

.steps {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 140px;
  margin-left: 39px;
}
.logoMeglin {
  width: 158px;
  height: 35px;
  margin-top: 40px;
  margin-left: 43px;
}

.stepsLoading {
  margin-top: 112px;
  z-index: -1;
  position: absolute;
}

.stepsLoadingP {
  margin-top: 101px;
  z-index: -1;
  position: absolute;
}

.stepsLoadingPP {
  margin-top: 112px;
  width: 300px;
  z-index: -1;
  position: absolute;
  height: 5px;
}

.logoFrame41 {
  margin-top: 120px;
  margin-left: 30px;
  z-index: -1;
  position: absolute;
  max-width: 248px;
  height: auto;
  image-rendering: initial; image-rendering: -webkit-optimize-contrast;
}

.stepsLoadingPPP {
  margin-top: 112px;
  margin-left: 266px;
  z-index: -1;
  position: absolute;
  height: 5px;
}

.stepsCount {
  margin-top: 125px;
  color: #7a7e89;
  font-weight: 400;
  font-size: 12px;
}



@media screen and (min-width: 770px) and (max-width: 1250px) {
  .ibmBlockRightRight {
    display: none;
  }
}


@media only screen and (max-width: 769px) {
  .ibmBlock {
   margin: 0px 20px;
  }
}
@media only screen and (max-width: 1250px) {
  
 .ibmBlock {
  flex-direction: column;
  border-radius: 30px;
  min-height: 509px;
  height: auto;
 }
 .ibmBlockLeft {
  border-radius: 30px;
  width: 100%;
  height: auto;
 }
 .ibmBlockRight {
   flex-direction: column;
 }
 .ibmBlockRightRight {
   display: none;
 }

 .ibmBlockTabletImg {
    display: block;
  }
  .ibmBlockRightLeft {
    width: 100%;
  }
  .ibmBlockRightTitle{
    flex-direction: row;
    flex-wrap: wrap;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 120%;

      &:after {
        content: '\a0';
      }
    }
  }
  .ibmBlockRightTitleBottom  {
    flex-direction: row;
    flex-wrap: wrap;

    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;

      &:after {
        content: '\a0';
      }
    }
  }
  .title {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 40px;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 120%;

      &:after {
        content: '\a0';
      }
    }
  }

  .stepsLoadingP {
    margin-top: 39px;
  }

  .steps .stepsLoading, .steps .stepsLoadingPP, .steps .stepsLoadingPPP {
    margin-top: 50px;
  }

  
  .steps .stepsCount {
    margin-top: 62px;
  }

  .ibmBlockTabletImg {
    img {
      // width: 90%;
      height: 300px;
      width: auto;
    }
  }

  .ibmBlockRightBottom {
    margin-bottom: 40px;
  }

 .ibmBlockRightTitleWrapper {
   margin-top: 30px;
 }

}


@media screen and (max-width: 450px) {
  .ibmBlockTabletImg {
     display: none ;
  }
  

  .ibmBlock {
    width: calc(100% - 40px);
    margin: 0;
  }

  .logoMeglin {
    margin-left: 30px;
  }
  
  .ibmBlockRightTitleWrapper {
    margin-right: 30px;
    margin-left: 30px;
  }

  .ibmBlockRightTitle span {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 22px !important;
    line-height: 110% !important;
  }

  .ibmBlockRightTitleBottom span {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 140% !important;
  }

  .steps {
    transform: scale(0.665);
    width: 100%;
    margin-left: -20px;
  }
}