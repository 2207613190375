.btnBlock {
  position: relative;
  transition: transform 0.2s ease-in-out;
}

.btn {
  white-space: nowrap;
  max-width: 200px;
  background: radial-gradient(161.84% 161.84% at 50% 50%, rgba(63, 66, 77, 0.441) 0%, rgba(63, 66, 77, 0.287) 100%);
  padding: 11px 20px;
  border: 1px solid rgba(242, 242, 242, 0.15);
  backdrop-filter: blur(10px);
  // max-width: 163px;
  height: 40px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
}

.btn:disabled {
  white-space: nowrap;
  max-width: 159px;
  background: radial-gradient(161.84% 161.84% at 50% 50%, rgba(63, 66, 77, 0.441) 0%, rgba(63, 66, 77, 0.287) 100%);
  padding: 11px 20px;
  border: 1px solid rgba(242, 242, 242, 0.15);
  backdrop-filter: blur(10px);
  // max-width: 163px;
  height: 40px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
}

.starLeft {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 45px;
  left: -7px;
  z-index: 3;
  opacity: 0;
}
.starTop {
  position: absolute;
  top: 7px;
  left: 88px;
  width: 20px;
  height: 20px;
  z-index: 3;
  opacity: 0;
}
.starBottom {
  animation: 3s linear 0s normal none infinite running stareffct;

  opacity: 0;
  position: absolute;
  top: 54px;
  left: 62px;
  width: 15px;
  height: 15px;
  z-index: 3;
}

