.tableWrap {
  position: relative;
  z-index: 0;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    tbody {
      tr:hover {
        background: #f4f6fa;
      }
    }

    tr {
      background: inherit;
    }

    th,
    td {
      min-width: 100px;
      max-width: 240px;
    }

    th {
      z-index: 2;

      background: #f4f6fa;
      font-size: 0.75rem;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      line-height: 110%;

      &[class~=sticky] {
        position: relative;
        z-index: 3 !important;

        &::before {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          left: 0;
          bottom: 100%;
          width:  100%;
          height: 100%;
          background: white;
        }
      }

      .headerTh {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 14px 7px 14px 15px;
        height: 19px;

        span {
          white-space: nowrap;
        }

        button {
          margin-left: .4em;
        }
      }

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 1;

        border-top-left-radius:    10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius:    10px;
        border-bottom-right-radius: 10px;
      }
    }

    td {
      font-size: 0.75rem;
      padding: 15px;

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        background: white;
      }
    }

    .walletInfo {
      p {
        margin: 0;
        font-weight: 400;
        color: #7a7e89;
        line-height: 110%;
      }
    }

    .assetsBlock {
      width: 180px;
    }

    .sourseWrapper {
      display: flex;
      flex-direction: column;
    }
  }
}