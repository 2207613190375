.investorBlock {
  margin: 11px 0px;
  display: flex;
  flex-direction: column;
  // min-width: 400px;
  min-height: 176px;
  border: 1px solid rgba(242, 242, 242, 0.15);
  border-radius: 15px;
  pointer-events: none;
  transition: transform 0.3s ease-in-out;
}
.investorBlock:hover {
  transform: translateY(-10px);
}
.top {
  display: flex;
  padding: 20px;
  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    span:first-child {
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
    }
    span:last-child {
      color: #7a7e89;
    }
  }
}
.avatar {
  margin-right: 20px;
  img {
    border-radius: 10px;
  }
}
.comment {
  font-weight: 400;
  font-size: 16px;
  color: #b9bdc8;
  padding: 0 20px 20px 20px;
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .investorBlock{
    margin: 0px 0px;
  }
}
@media screen and (max-width: 768px) {
  .investorBlock {
    margin: 11px 0px;
  }
}


@media screen and (max-width: 499px) {
  .investorBlock{
    margin: 11px 20px;
    min-width: 200px;
  }
}

