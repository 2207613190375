.wrapper {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
}

.chartWrap {
  width: calc(100% - 40px);
  height: 360px;
  border: 1px solid rgba(185, 189, 200, 0.3);
  border-radius: 10px;
  background-color: #fff;
}

.overviewChart {
  height: 256px;
}

.tableTitle {
  margin: 0;
  margin-top: 40px;
  margin-bottom: 30px;

  font-family: 'Subjectivity';
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;
  color: #1A1D21;
}

.pagination {
  display: flex;
  justify-content: center;

  .list {
    display: flex;
    width: 240px;
    height: 30px;
    margin: 19px 0 40px;

    list-style-type: none;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin-right: 8px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .active {
      border: 1px solid #dfe3ec;
      border-radius: 12.0492px;
    }
  }
}

.btnWrap {
  margin: 30px;
  width:  24px;
  height: 24px;

  .btnDownload {
    display: flex;
    justify-content: center;
    border: none;
    background: #ffffff;
    width:  24px;
    height: 24px;

    svg {
      width:  18px;
      height: 18px;
    }
  }
}

.coverage {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  > * + * {
    margin-left: 15px;
  }

  span {
    font-size: 12px;
    color: #B9BDC8;
  }
}

.flexRowHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > * + * {
    margin-left: 20px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
}

.stats {
  display: flex;
  align-items: center;

  > * {
    flex-grow: 1;

    + * {
      margin-left: 20px;
    }
  }

  &Label {
    box-sizing: border-box;

    position: relative;
    z-index: 0;

    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 20px;
    height: 40px;
    border: 1px solid rgba(220, 1, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 10px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #1A1D21;

    span {
      margin-left: 20px;

      font-style: normal;
      font-weight: 400;
      font-size: 16px;
    }
  }
}

.addButton {
  margin-left: 10px;
  background: transparent;
  border: none;
  padding: 10px 15px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  border: 1px solid rgba(220, 1, 255, 0.2);
  border-radius: 10px;
  background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  cursor: pointer;

  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
  }

  img,
  svg {
    margin-right: 13px;
  }
}

.conentWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  cursor: pointer;
}

thead {
  cursor: auto;
}

.sidebar {
  position: sticky;
  top: 12px;
  flex-shrink: 0;
  background: #FFFFFF;
  width: 352px;
  margin-left: 20px;
  padding: 20px;
  border: 1px solid #E8EDF5;
  border-radius: 15px;

  font-size: 12px;

  &CloseButton {
    position: absolute;
    right: 10px;
    top:   10px;

    width:  20px;
    height: 20px;
    background: none;
    border: none;
    padding: 0;

    cursor: pointer;

    svg {
      width:  100%;
      height: 100%;
    }
  }

  &__ens {
    display: flex;
    align-items: flex-start;
    margin: 0;
    margin-bottom: 17px;

    font-family: 'Subjectivity';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #1A1D21;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__address {
    display: flex;
    align-items: center;

    h3 {
      margin: 0;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
    }

    .copyButton {
      margin-left: 5px;
    }
  }

  &Title {
    margin: 0;
    margin-bottom: .35em;

    font-family: 'Subjectivity';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
    color: #1A1D21;
  }
}

.pagination {
  margin-top: 30px;
}