@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

@font-face {
  font-family: 'Subjectivity';
  src: url('fonts/Subjectivity/Subjectivity-Medium.woff2') format('woff'),
    url('fonts/Subjectivity/Subjectivity-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Subjectivity';
  src: url('fonts/Subjectivity/Subjectivity-Light.woff2') format('woff'),
    url('fonts/Subjectivity/Subjectivity-Light.otf') format('opentype');
  font-weight: 300;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  background: #191b1f;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
}

