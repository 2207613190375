.btn {
  background: linear-gradient(rgba(63, 66, 77, 0.441),rgba(120, 123, 135, 0.41)); 
  border: 1px solid rgba(242, 242, 242, 0.15) !important;
  backdrop-filter: blur(10px);
  width: 160px;
  height: 40px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  border: none;
  background-size:1px 200px;  
  transition: all 0.3s ease-out;  

  &:hover {
    background-position:100px;  
  }
  &:active {
    background-position: 0;
    opacity: 0.7;
  }
}
