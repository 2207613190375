.wrapperOwner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 335px;
}

.hrr {
  border: none;
  color: red;
  background-color: red;
  width: 355px;
  height: 2px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 335px;
}

.wrapperTitle {
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  color: #FFFFFF;
  margin-top: 100px;
}

@media screen and (max-width: 482px) {
  .wrapperTitle {
    margin-top: 20px;
  }
}

.wrapperTitleTwo {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  color: #B9BDC8;
  margin-top: 20px;
}

form {
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-top: 10px;
}

.input {
  padding-left: 10px;
  border-radius: 12px;
  width: 330px;
  height: 42px;
  background-color: #3F424D;
  border: 1px solid rgba(242, 242, 242, 0.15);
  margin-top: 15px;
  color: white;
  // font-weight: 400;
  // font-size: 16px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.inputTextInfo {
  padding-left: 10px;
  border-radius: 12px;
  width: 330px;
  height: 80px;
  background-color: #3F424D;
  border: 1px solid rgba(242, 242, 242, 0.15);
  margin-top: 15px;
  color: white;


  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}
.inputTextAreaInfo {
  resize: vertical;
  
  padding-top: 10px;
  padding-left: 10px;
  border-radius: 12px;
  width: 330px;
  height: 80px;
  background-color: #3F424D;
  border: 1px solid rgba(242, 242, 242, 0.15);
  margin-top: 15px;
  color: white;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

form input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 1px solid #46494F;
  border-radius: 4px;
  background: #46494F;
}

form input[type="checkbox"]:before {

  border: 1px solid #46494F;
  border-radius: 4px;
  background: #46494F;
}

// form input[type="email"]:invalid {
//   border: 2px solid #E01E5A;
// }

.dirtyForm {
  input[type="email"]:invalid {
    border: 2px solid #E01E5A;
  }
  input[type="text"]:invalid {
    border: 2px solid #E01E5A;
  }

}

customStyles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 12px;
}

.Btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.container {
  display: block;
  position: relative;
  margin-top: 30px;
  padding-left: 30px;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  color: #7A7E89;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #191b1f;
  border: 1px solid #46494F;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #13171b;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #191b1f;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  border: solid white 0px;
  background: linear-gradient(115.74deg, #DC01FF 13.06%, #3736FF 99.99%);;
}

@media screen and (max-width: 499px) {
  
}

@media screen and (max-height: 800px) and (max-width: 1280px) {
  .wrapperTitle {
    margin-top: 10px;
  }
}