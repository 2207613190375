.wrapperBlocks {
  position: relative;

  display: flex;
}

.answerBlock {
  position: relative;
  backdrop-filter: blur(20px);
  width: 630px;
  height: 650px;
  background: radial-gradient(
    161.84% 161.84% at 50% 50%,
    rgba(101, 101, 101, 0.126) 0%,
    rgba(101, 101, 101, 0.082) 100%
  );
  border: 1px solid rgba(242, 242, 242, 0.15);
  border-radius: 30px;
  margin-bottom: 20px;
  h1 {
    font-family: 'Subjectivity';
    color: white;
    font-weight: 500;
    font-size: 40px;
  }
  h2 {
    margin: 20px 0 20px 0;
    font-weight: 400;
    font-size: 18px;
    color: #b9bdc8;
  }
}
.answerTitles {
  padding: 80px;
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
    .answerBlock {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      
    }
    .wrapperBlocks {
      width: calc(50% - 10px);
    }
}


@media screen and (max-width: 768px) {
  .wrapperBlocks {
    width: 100%;
  }
  .answerBlock {
    width: 100%;
    height: auto;
    padding-bottom: 40px;
  }
  
}


@media screen and (max-width: 450px) {
  .answerBlock {
    width: 100%;
    height: auto;
    padding-bottom: 0px;
  }
}

