.wrapper {
  position: relative;
  z-index: 0;

  width: fit-content;
  display: flex;
  align-items: center;
}

.child {
  position: absolute;
  top:  0;

  display: flex;
  justify-content: flex-end;
  width: 60px;

  > * {
    box-shadow: 0 0 0 1px white;
  }

  &:first-of-type {
    position: static;
    width: fit-content;
  }
}

.count {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  width:  18px;
  height: 18px;
  border-radius: 50%;
  background: #F4F6FA;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10.0725px;
  line-height: 110%;
  text-align: center;
  color: #003BD2;
}