.slider {
  position: relative;
}
.carousel {
  overflow: hidden;
  margin-left: 20px;
}
.innerCarousel {
  display: flex;
}

.SliderWrapper {
  // width: 100%;
  // padding-left: 50px;
  // box-sizing: border-box;
  padding-top: 30px;
}
.SwiperSlide {
  // max-width: 420px;
  width: 90%;
}